import { useMemo } from "react";
import { isFuture, isPast, parseISO } from "date-fns";
import { useFirestore } from "hooks/firestore/v2/useFirestore";
import { statusMessage } from "hooks/firestore/v2/models/statusMessage";

import type { Always } from "types/utilities";

const useStatusMessages = () => {
  const { data, isLoading, error } = useFirestore(
    "/statusMessages",
    statusMessage.array(),
    {
      keyPath: "id",
    },
  );

  const statusMessages = useMemo(() => {
    if (!data) return;

    return data.filter((message) => {
      const startDate = parseISO(message.scheduledStartTime);
      const endDate = message?.scheduledExpiryTime
        ? parseISO(message.scheduledExpiryTime)
        : null;

      if (isFuture(startDate)) {
        return false;
      }

      if (endDate && isPast(endDate)) {
        return false;
      }

      return true;
    });
  }, [data]);

  return { statusMessages, isLoading, error };
};

type StatusMessage = Always<
  ReturnType<typeof useStatusMessages>["statusMessages"]
>[0];

export { useStatusMessages, type StatusMessage };
