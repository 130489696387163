import { useFirestore } from "hooks/firestore/v2/useFirestore";
import {
  tokenAutomation,
  type TokenAutomation,
} from "hooks/firestore/v2/models/tokenAutomation";
import {
  type TokenAllocation,
  tokenAllocation,
} from "hooks/firestore/v2/models/tokenAllocation";
import { useMemo } from "react";
import { isFuture } from "date-fns";

const typeToNameMap: Record<string, string> = {
  STAKE_REFUND: "Bet Reload",
  WINNINGS_BOOST: "Winnings Boost",
};

const useTokens = (excludeEventTokens = false) => {
  const { data: definitions, isLoading: definitionsLoading } = useFirestore(
    "/tokenAutomations",
    tokenAutomation.array(),
  );

  const { data: allocations, isLoading: allocationsLoading } = useFirestore(
    "/users/{userId}/tokenAllocations",
    tokenAllocation.array(),
    {
      keyPath: "id",
    },
  );

  const groupedTokens = useMemo(() => {
    if (definitionsLoading || allocationsLoading) {
      return {};
    }

    return (definitions || []).reduce(
      (
        tokensByType: Record<
          string,
          {
            name: string;
            tokens: ({
              allocations: (Omit<TokenAllocation, "createdAt" | "expiresAt"> & {
                createdAt: Date;
                expiresAt: Date;
              })[];
            } & TokenAutomation)[];
          }
        >,
        definition,
      ) => {
        // we can assume that a token will only have 1 action, so we can just grab the first one
        const action = definition.actions[0];

        if (!action) {
          return tokensByType;
        }

        // do not include event tokens
        if (excludeEventTokens && definition?.attributes?.eventBasedTokens) {
          return tokensByType;
        }

        // find all allocations that match this definition
        const allocationsForDefinition = (allocations || [])
          .filter(
            (allocation) =>
              allocation.automationId === definition.automationId &&
              isFuture(allocation.expiresAt.toDate()) &&
              !allocation.markedForDeletion,
          )
          .map((allocation) => ({
            ...allocation,
            expiresAt: allocation.expiresAt.toDate(),
            createdAt: allocation.createdAt.toDate(),
          }));

        // if group doesn't exist, create it
        if (!tokensByType[action.type]) {
          tokensByType[action.type] = {
            name: typeToNameMap[action.type],
            tokens: [],
          };
        }

        // add definition to group if there are active allocations
        if (allocationsForDefinition.length > 0) {
          tokensByType[action.type].tokens.push({
            ...definition,
            allocations: allocationsForDefinition,
          });
        }

        if (tokensByType[action.type].tokens.length === 0) {
          delete tokensByType[action.type];
        }

        return tokensByType;
      },
      {},
    );
  }, [definitions, definitionsLoading, allocations, allocationsLoading]);

  return {
    tokens: groupedTokens,
    isLoading: definitionsLoading || allocationsLoading,
  };
};

type GroupedTokens = Exclude<ReturnType<typeof useTokens>["tokens"], undefined>;

export { useTokens, type GroupedTokens };
