import { useMemo } from "react";
import { useFiatCurrency } from "hooks";
import type { PromotionClaim } from "types/PromotionTypes";
import { useFirestore } from "hooks/firestore/v2/useFirestore";
import { currentAutomationProgression } from "hooks/firestore/v2/models/currentAutomationProgression";

const usePromotionClaims = () => {
  const currency = useFiatCurrency();

  const { data, isLoading } = useFirestore(
    "/users/{userId}/currentAutomationProgressions",
    currentAutomationProgression.array(),
  );

  const claims = useMemo(() => {
    if (!data) return [];

    return (
      data
        // exclude progressions that are not deposit matches
        .filter((progression) => {
          return (
            progression.actions.some(
              (action) => action.type === "DEPOSIT_MATCH",
            ) &&
            progression.requirements.some(
              (requirement) => requirement.type === "DEPOSIT",
            )
          );
        })
        // convert to promotion claim type
        .map((progression) => {
          const projectedRewardInfo = progression.actions?.find(
            (requirement) => requirement.type === "DEPOSIT_MATCH",
          )?.projectedRewardInfo;

          return {
            code: progression.progressionId,
            expireAt: progression.expiresAt?.toDate(),
            amount: projectedRewardInfo?.max || 0,
            currency,
            name: progression.name,
            matchPercentage: projectedRewardInfo?.percent || 0,
            purpose: "DEPOSIT_BONUS", // for now, we are mapping to legacy until we can remove all legacy claims logic
            paidStatus: "PENDING", // for now, we are mapping to legacy until we can remove all legacy claims logic
          } satisfies PromotionClaim;
        })
    );
  }, [data, currency]);

  return { claims, isLoading };
};

type PromotionClaims = Exclude<
  ReturnType<typeof usePromotionClaims>["claims"],
  undefined
>;

export { usePromotionClaims, type PromotionClaims };
