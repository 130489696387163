import React, { useEffect } from "react";
import cx from "classnames";
import { LinkButton } from "components/LinkButton";
import { useStatusMessages } from "hooks/firestore/v2/useStatusMessages";
import { useStatusMessageOffset } from "hooks/useHeaderOffset";
import { useElementSize } from "usehooks-ts";
import { useDispatch } from "hooks";
import { setStatusMessageHeight } from "utilities/UI/uiSlice";
import { ReactComponent as DangerIcon } from "components/assets/danger.svg";
import { ReactComponent as CheckIcon } from "components/assets/success.svg";
import { ReactComponent as InfoIcon } from "components/assets/info.svg";
import { ReactComponent as WarningIcon } from "components/assets/warning.svg";

import * as styles from "./StatusMessage.module.scss";

const icons = {
  danger: <DangerIcon />,
  warning: <WarningIcon />,
  info: <InfoIcon />,
  success: <CheckIcon />,
};

const StatusMessage = () => {
  const { statusMessages, isLoading } = useStatusMessages();
  const topOffset = useStatusMessageOffset();
  const [ref, { height }] = useElementSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setStatusMessageHeight(height));
  }, [height]);

  if (isLoading || !statusMessages) {
    // render nothing if there is no active status messages
    return null;
  }

  // grab latest status message out of the list as it is probably the most relevant one
  // ideally we never run more than 1 at the same time
  const statusMessage = statusMessages[statusMessages.length - 1];
  const type = statusMessage.type.toLowerCase() as keyof typeof icons;

  return (
    <div
      ref={ref}
      className={cx(styles.container, {
        [styles.danger]: type === "danger",
        [styles.info]: type === "info",
        [styles.warning]: type === "warning",
        [styles.success]: type === "success",
      })}
      style={{ top: topOffset }}
    >
      <div className={styles.content}>
        <div className={styles.icon}>{icons[type]}</div>
        <div className={styles.description}>
          {statusMessage.description}
          {statusMessage.moreInfoUrl && (
            <LinkButton
              className={styles.cta}
              variant={type as any}
              size={`xs`}
              to={statusMessage.moreInfoUrl}
            >
              More Info
            </LinkButton>
          )}
        </div>
      </div>
    </div>
  );
};

export { StatusMessage };
