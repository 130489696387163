import React from "react";
import { motion } from "framer-motion";
import {
  useDispatch,
  useDisplayAtLeast,
  useDisplayMode,
  useSelector,
} from "hooks";
import { useTitles } from "hooks/graphql/useTitles";
import { useDragGesture } from "hooks/useDragGesture";
import { Betslip } from "sections/Betting/Betslip/Betslip";
import { SearchMenu } from "library";
import { PickSlip } from "sections/Pickems/PickSlip";
import { useIsClient } from "usehooks-ts";
import { setMenu } from "utilities/UI/uiSlice";
import { useHeaderOffset } from "hooks/useHeaderOffset";

import * as styles from "./Panel.module.scss";

const constraints = {
  dragElastic: { right: 0.7, left: 0 },
};

const Panel = () => {
  const isClient = useIsClient();
  const menu = useSelector((state: any) => state.utilities.ui.menu);
  const panel = useSelector((state: any) => state.utilities.ui.panel);
  const displayMode = useDisplayMode();
  const atLeastDesktopXl = useDisplayAtLeast("desktopXl");
  const titles = useTitles();
  const topOffset = useHeaderOffset();

  const dispatch = useDispatch();
  const dragToClose = useDragGesture(constraints, (_, info) => {
    if (info.offset.x > 135 || info.velocity.x > 50) dispatch(setMenu("none"));
  });

  if (!isClient) {
    // betslip is not currently SSR compatible due to react-activity,
    // we will re-enable this once Activity becomes a part of core react package
    return null;
  }

  return (
    <motion.div
      key={displayMode}
      initial={atLeastDesktopXl ? { x: 0 } : { x: "100%" }}
      animate={menu === "right" ? { x: 0 } : {}}
      transition={{ type: "spring", bounce: 0, duration: 0.2 }}
      className={styles.panel}
      style={{ ...(topOffset && { top: topOffset }) }}
      {...dragToClose}
    >
      {panel === "search" ? <SearchMenu titles={titles} /> : null}
      {panel === "betslip" ? <Betslip /> : null}
      {panel === "pickslip" ? <PickSlip /> : null}
    </motion.div>
  );
};

export default Panel;
