import {
  doc,
  type FirestoreDataConverter,
  collection,
  query,
  where,
} from "firebase/firestore";
import { useMemo } from "react";
import { useDocumentData, useCollectionData } from "hooks";

import {
  createEventsConverter,
  createMarketsConverter,
} from "./betting/useBetting";
import {
  createRaceMarketsConverter,
  createRacingEventsConverter,
} from "sections/Betting/Race/hooks/useRace";
import useHasPermission, { usePermissions } from "../useHasPermission";
import type { SourceType } from "hooks/firestore/betting/types";
import { getFirestore } from "store/getFirebase";
import { useCampaigns } from "hooks/firestore/v2/user/useCampaigns";

export type StatusAndOddsOutcome = {
  type: string;
  active: boolean;
  abbreviation: string;
  result: string;
  openingOdds: number;
  attributes: any;
  odds: number;
  name: string;
  competitorId: string;
  canViewLiveBets: boolean;
  eventStatus: string;
  marketStatus: string;
  streamUri: string;
};

export type Outcome = {
  marketId: string;
  outcomeId: string;
};

type StatusAndOddsByOutcomeId = Record<string, StatusAndOddsOutcome>;

export const useFirestoreOutcomeStatusAndOdds = (
  outcomeId: string,
  eventId: string,
  marketId: string,
  sourceType: SourceType,
): [StatusAndOddsOutcome, boolean] => {
  const [outcomes, loading] = useMultipleOutcomeStatusAndOdds(
    eventId,
    sourceType,
    [{ marketId, outcomeId }],
  );

  return [outcomes[outcomeId] || ({} as StatusAndOddsOutcome), loading];
};

export const useMultipleOutcomeStatusAndOdds = (
  eventId: string,
  sourceType: SourceType,
  outcomes: Outcome[],
): [StatusAndOddsByOutcomeId, boolean] => {
  const { codes: userCampaigns } = useCampaigns();
  const permissions = usePermissions();

  const eventConverter = useMemo(
    () => createEventsConverter(userCampaigns, permissions),
    [userCampaigns],
  );

  const racingEventsConverter = useMemo(
    () => createRacingEventsConverter(userCampaigns, permissions),
    [userCampaigns],
  );

  const marketsConverter = useMemo(
    () => createMarketsConverter(userCampaigns, permissions),
    [userCampaigns],
  );

  const raceMarketsConverter = useMemo(
    () => createRaceMarketsConverter(userCampaigns, permissions),
    [userCampaigns],
  );

  const collectionProperties = useMemo(
    () =>
      sourceType === "race" || sourceType === "exotic"
        ? {
            collection: "racingEvents",
            converter: racingEventsConverter,
            marketConverter: raceMarketsConverter,
          }
        : {
            collection: "bettingEvents",
            converter: eventConverter,
            marketConverter: marketsConverter,
          },
    [sourceType],
  ) as {
    collection: string;
    converter: FirestoreDataConverter<any>;
    marketConverter: FirestoreDataConverter<any>;
  };

  const canViewLiveBets = useHasPermission("viewLiveMarkets");

  const eventsRef = useMemo(() => {
    return eventId
      ? doc(
          getFirestore(),
          collectionProperties.collection,
          eventId,
        ).withConverter(collectionProperties.converter)
      : null;
  }, [eventId]);

  const marketIds = useMemo(
    () => outcomes.map((outcome) => outcome.marketId),
    [outcomes],
  );

  const marketsRef = useMemo(() => {
    return eventId && marketIds[0]
      ? query(
          collection(eventsRef, "markets").withConverter(marketsConverter),
          where("id", "in", marketIds),
        )
      : null;
  }, [eventsRef, marketIds, eventId]);

  const [event, eventLoading] = useDocumentData(eventsRef);
  const [markets, marketsLoading] = useCollectionData(
    marketsRef,
    `markets/${marketIds.join(",")}`,
  );

  const outcomeStatuses = useMemo(() => {
    if (!event || !markets) return {};

    return outcomes.reduce((acc, outcomeAndMarket) => {
      const market = markets.find((m) => m.id === outcomeAndMarket.marketId);
      const localMarket =
        event.mainMarket?.id === outcomeAndMarket.marketId
          ? event.mainMarket
          : market;
      const outcome = localMarket?.outcomes?.[outcomeAndMarket.outcomeId] ?? {};
      const eventStatus = event?.status ?? market?.status ?? "";

      acc[outcomeAndMarket.outcomeId] = {
        ...outcome,
        canViewLiveBets,
        eventStatus,
        marketStatus: market?.status,
        streamUri: event?.streamUri,
      };

      return acc;
    }, {} as StatusAndOddsByOutcomeId);
  }, [outcomes, event, markets, marketsLoading, eventLoading]);

  return [outcomeStatuses, marketsLoading || eventLoading];
};
