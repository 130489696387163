import { z } from "zod";
import type { infer as zInfer } from "zod";
import { timestamp } from "hooks/firestore/v2/models/timestamp";

export const tokenAllocation = z.object({
  id: z.string(),
  automationId: z.string(),
  createdAt: timestamp,
  expiresAt: timestamp,
  markedForDeletion: z.boolean(),
});

export type TokenAllocation = zInfer<typeof tokenAllocation>;
