import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useUser } from "./useUser";

type Preferences = {
  acceptOdds: "MANUAL" | "AUTO" | "AUTO_HIGHER";
  oddsFormat: "DECIMAL" | "AMERICAN" | "FRACTIONAL";
  defaultHub: "SPORTS" | "ESPORTS" | "RACING";
  allowCancelWithdrawal: boolean;
};

export const defaultPreferences = {
  acceptOdds: "AUTO_HIGHER",
  oddsFormat: "DECIMAL",
  defaultHub: "RACING",
  allowCancelWithdrawal: true,
} satisfies Preferences;

const usePreferences = () => {
  const [cachedPreferences, setCachedPreferences] =
    useLocalStorage<Preferences>(`userPreferences`, defaultPreferences);

  const { user, isLoading } = useUser();
  const preferences = user?.settings;

  useEffect(() => {
    if (isLoading || !preferences) {
      return;
    }

    const mergedPreferences = {
      ...defaultPreferences,
      ...preferences,
    };

    setCachedPreferences(mergedPreferences satisfies Preferences);
  }, [
    isLoading,
    // using each property of preferences here to force a re-save when they change
    preferences?.allowCancelWithdrawal,
    preferences?.defaultHub,
    preferences?.acceptOdds,
    preferences?.oddsFormat,
  ]);

  return {
    preferences: cachedPreferences,
    isLoading,
  } as const;
};

const useHomeUrl = (): string => {
  const isBrowser = typeof window !== "undefined";

  const { preferences } = usePreferences();

  if (!isBrowser) return "/betting";

  if (preferences?.defaultHub === "SPORTS") {
    return `/sports/betting`;
  } else if (preferences?.defaultHub === "RACING") {
    return `/racing/betting`;
  }

  return `/betting`;
};

export { usePreferences, type Preferences, useHomeUrl };
