import useCalculatePlayerOffset from "hooks/useCalculatePlayerOffset";
import useCalculateSmartBannerOffset from "hooks/useCalculateSmartBannerOffset";
import { useSelector } from "./useSelector";

const useHeaderOffset = () => {
  const playerHeight = useCalculatePlayerOffset();
  const bannerHeight = useCalculateSmartBannerOffset();
  const statusHeight = useSelector(
    (state) => state.utilities.ui.statusMessageHeight,
  );

  return playerHeight + bannerHeight + statusHeight;
};

const useStatusMessageOffset = () => {
  const playerHeight = useCalculatePlayerOffset();
  const bannerHeight = useCalculateSmartBannerOffset();

  return playerHeight + bannerHeight;
};

export { useHeaderOffset, useStatusMessageOffset };
