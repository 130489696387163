import React, { useMemo } from "react";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { Icon } from "library";
import { ScrollArea } from "components/ScrollArea";
import { setModal } from "utilities/UI/uiSlice";
import { useIsMobile } from "hooks/useDisplay";
import Items from "./Items";
import useHubs from "hooks/graphql/useHubs";
import { TitleIcon } from "components/TitleIcon";
import { useTitles } from "hooks/graphql/useTitles";
import { getTotalCountForSports } from "utilities/helpers";
import type { MenuItem, HubMappings } from "./types";
import { useLocation } from "hooks/useLocation";
import { usePageContext } from "hooks/usePageContext";
import CondensedMenu from "./CondensedMenu";
import { useIsClient } from "usehooks-ts";
import * as support from "utilities/support";
import MenuHeader from "./MenuHeader";
import {
  useLiveEntriesCount,
  useUpcomingEntriesCount,
} from "hooks/firestore/betting/useEntries";
import { useIsLoggedIn, useIsProfileComplete, usePermissions } from "hooks";
import { setMenu } from "utilities/UI/uiSlice";
import { useDragGesture } from "hooks/useDragGesture";
import useMustHidePickems from "hooks/useMustHidePickems";
import { usePreferences } from "hooks/firestore/v2/user/usePreferences";
import { useEventCounts } from "hooks/firestore/v2/betting/useEventCounts";
import { useHeaderOffset } from "hooks/useHeaderOffset";

import * as styles from "./MainMenu.module.scss";

const hubMappings: HubMappings = {
  esports: {
    permissions: ["viewBettingMarkets", "viewEsportsMarkets"],
    icon: "gameController",
  },
  racing: {
    permissions: ["viewBettingMarkets", "viewRacingMarkets"],
    icon: "racing",
  },
  sports: {
    permissions: ["viewBettingMarkets", "viewSportsMarkets"],
    icon: "sports",
  },
};

const constraints = {
  dragElastic: { left: 0.7, right: 0 },
};

const MainMenu = () => {
  const isClient = useIsClient();
  const { pathnameWithoutLocale: pathname } = useLocation();
  const dispatch = useDispatch();
  const menu = useSelector((state: any) => state.utilities.ui.menu);
  const { preferences: userPreferences } = usePreferences();
  const hubs = useHubs();
  const titles = useTitles();
  const { hub: currentHub, title: currentTitle } = usePageContext();
  const liveEntriesCount = useLiveEntriesCount();
  const upcomingEntriesCount = useUpcomingEntriesCount();
  const topOffset = useHeaderOffset();
  const entriesCount = liveEntriesCount + upcomingEntriesCount;
  const permissions = usePermissions();
  const profileComplete = useIsProfileComplete();
  const { eventCounts } = useEventCounts();
  const isLoggedIn = useIsLoggedIn();
  const mustHidePickems = useMustHidePickems();

  const isMobile = useIsMobile();

  const dragToClose = useDragGesture(constraints, (_, info) => {
    if (info.offset.x < -135 || info.velocity.x < -50)
      dispatch(setMenu("none"));
  });

  const menuItems = useMemo<MenuItem[]>(() => {
    const items = [];
    // region Account
    items.push({
      name: "My Account",
      permissions: ["user"],
      icon: <Icon type={`profile`} />,
      sections: ["entries", "preferences", "perks", "account", "wallet"],
      to: "/account",
      items: [
        {
          name: "Deposit",
          icon: <Icon type="dollar" />,
          to: "/wallet/deposit",
          // NOTE: this is specifically, so it only picks up deposit link out of entire section
          isActive: pathname === "/wallet/deposit/",
        },
        {
          name: "Bets & Picks",
          icon: <Icon type="chip" />,
          to: "/entries/upcoming",
          badge: entriesCount > 0 ? entriesCount.toString() : undefined,
          isActive: pathname.match(/\/entries\//),
        },
        {
          name: "Pickle Perks",
          icon: <Icon type="bonus" />,
          to: "/perks",
          isActive: pathname.match(/\/perks\//),
        },
        {
          name: "Wallet",
          icon: <Icon type="wallet" />,
          to: "/wallet/statement",
          isActive: pathname.match(/\/wallet\/(?!deposit\/).*/),
        },
        {
          name: "Preferences",
          icon: <Icon type="settings" />,
          to: "/preferences/betting",
          isActive: pathname.match(/\/preferences\//),
        },
        {
          name: "Details",
          icon: <Icon type="details" />,
          to: "/account/details",
          isActive: pathname.match(/\/account\//),
        },
        {
          name: "Logout",
          icon: <Icon type="logout" />,
          onClick: () => dispatch(setModal("logout")),
        },
      ],
    });
    // endregion

    // region Betting Hubs
    const defaultHub = hubs.find(
      (hub) => hub.slug === userPreferences.defaultHub.toLowerCase(),
    );

    const orderedHubs = [
      defaultHub.slug,
      ...hubs
        .filter((hub) => hub.slug !== userPreferences.defaultHub.toLowerCase())
        .map((hub) => hub.slug),
    ];

    orderedHubs.forEach((slug) => {
      const hub = hubs.find((hub) => hub.slug === slug);
      items.push({
        name: hub.name,
        permissions: hubMappings[hub.slug].permissions,
        icon: <Icon type={hubMappings[hub.slug].icon} />,
        to: `/${hub.pathPrefix ? `${hub.pathPrefix}/` : ``}betting`,
        items: [
          {
            name: `All ${hub.name}`,
            icon: <Icon type={`all`} />,
            to: `/${hub.pathPrefix ? `${hub.pathPrefix}/` : ``}betting`,
            isActive: hub.slug === currentHub && !currentTitle,
          },
          ...hub.titles
            .map((title) => {
              const count = getTotalCountForSports(
                eventCounts,
                [title?.slug as string],
                ["matches", "outrights", "racing"],
              );

              const matchesCount =
                eventCounts?.["matches"]?.[title?.slug as string] ?? 0;

              return {
                ...title,
                count,
                matchesCount,
              };
            })
            .filter((title) => title.count > 0)
            .map((title) => {
              const prefix =
                "/" + [hub.pathPrefix, title.slug].filter(Boolean).join("/");

              let tabs = "";
              if (hub.pathPrefix !== "racing") {
                tabs =
                  title.matchesCount === 0
                    ? "?page=1&tab=outrights"
                    : "?page=1&tab=next";
              }

              return {
                name: title.name,
                icon: <TitleIcon title={title} svg />,
                to: `${prefix}/betting${tabs}`,
                badge: title.count,
                title: title.slug,
              };
            }),
        ],
        sections: [`${hub.pathPrefix ? `${hub.pathPrefix}/` : ``}betting`],
      });
    });

    // endregion

    // region Pick’ems
    if (!mustHidePickems) {
      items.push({
        name: "Pick’ems",
        icon: <Icon type={`pick`} />,
        to: `/pickems`,
        items: [
          {
            name: `All Pick’ems`,
            icon: <Icon type={`all`} />,
            to: `/pickems`,
          },
          ...Object.values(titles)
            .map((title) => {
              const count = getTotalCountForSports(
                eventCounts,
                [title.slug],
                ["pickems"],
              );

              return {
                ...title,
                count,
              };
            })
            .filter((title) => title.count > 0)
            .map((title) => {
              return {
                name: title.name,
                icon: <TitleIcon title={title} svg />,
                to: `/${title.slug}/pickems`,
                badge: title.count,
                title: title.slug,
              };
            }),
        ],
        sections: ["pickems"],
      });
    }
    // endregion

    // region Static pages
    items.push(
      {
        name: "Promotions",
        icon: <Icon type={`star`} />,
        sections: ["promotions"],
        to: `/promotions`,
      },
      {
        name: "Terms",
        icon: <Icon type={`terms`} />,
        onClick: () => support.open({ labels: ["terms button"] }),
      },
      {
        name: "Support",
        icon: <Icon type={`help`} />,
        onClick: () => support.open({ labels: ["start article"] }),
      },
    );
    // endregion
    return items.filter((item) => {
      // do not attempt to filter out permissions till we had a first mount on client side
      if (item.permissions && !isClient) return false;

      return (item.permissions || []).every(
        (itemPermission: string) => permissions[itemPermission] === "GRANTED",
      );
    });
  }, [
    userPreferences.defaultHub,
    eventCounts,
    pathname,
    liveEntriesCount,
    upcomingEntriesCount,
    permissions,
    isLoggedIn,
    profileComplete,
    isClient,
  ]);

  return (
    <>
      <CondensedMenu items={menuItems} />
      <motion.aside
        initial={{ x: "-100%" }}
        animate={menu === "left" ? { x: 0 } : {}}
        transition={{ type: "spring", bounce: 0, duration: 0.2 }}
        role={"menu"}
        className={styles.menu}
        style={{ ...(topOffset && { top: topOffset }) }}
        {...dragToClose}
      >
        <MenuHeader />
        <ScrollArea
          className={styles.scrollArea}
          viewPortClassName={styles.viewport}
          asChild={isClient && isMobile}
        >
          {isClient ? <Items items={menuItems} /> : null}
        </ScrollArea>
      </motion.aside>
    </>
  );
};

export default MainMenu;
