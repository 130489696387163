import { useState, useEffect, useMemo } from "react";
import { getFirestore } from "store/getFirebase";
import { useCollectionData, usePermissions } from "hooks";

import { collection, query, where, orderBy, limit } from "firebase/firestore";
import { createRacingEventsConverter } from "./useRace";
import type { RaceEventType } from "./RacingTypes";
import type { FirebaseError } from "firebase/app";
import { titleSportMapping } from "sections/Betting/Racing/components/RaceMeetings/RaceMeetings";
import {
  type RacingRouteParams,
  RegionType,
} from "sections/Betting/Racing/hooks/useRacingRoute";
import { useUserEventFilters } from "hooks/firestore/useUserAttributes";
import { useCampaigns } from "hooks/firestore/v2/user/useCampaigns";
import type { Maybe } from "types/utilities";

export const NEXT_TO_GO_LIMIT = 50;

export type RaceSportTypes = "HORSE_RACING" | "GREYHOUNDS" | "HARNESS_RACING";

export type RaceRegionTypes = "ANZ" | "ROW";

export const selectedTitlesToSportMapping = (selectedtitles: string[]) => {
  if (selectedtitles) {
    const mappings = Object.entries(titleSportMapping);

    if (selectedtitles.length > 0) {
      const mappedTitles = selectedtitles.map(
        (x) => mappings.find(([_, val]) => val === x)?.[0] ?? "",
      );

      return mappedTitles;
    }

    return [];
  }
};

const getRacingEventsQueryForNextToGo = (
  route: Partial<RacingRouteParams>,
  userEventFilters: string[],
) => {
  const queries = [];
  queries.push(where("status", "==", "OPEN"), where("visible", "==", true));

  if (route?.region?.length === 1) {
    if (route?.region?.includes(RegionType.au)) {
      queries.push(where("region", "==", "ANZ"));
    } else {
      queries.push(where("region", "==", "ROW"));
    }
  }

  if (route.sports) {
    queries.push(
      where("sport", "in", selectedTitlesToSportMapping(route.sports)),
    );
  }

  if (userEventFilters?.length > 0) {
    queries.push(where("filters", "array-contains-any", userEventFilters));
  }

  queries.push(
    orderBy("scheduledStartTimeTs", "asc"),
    limit(NEXT_TO_GO_LIMIT * Number(route.page)),
  );

  return queries;
};

export const useRacingEvents = (
  route: Partial<RacingRouteParams>,
): [RaceEventType[], boolean, Maybe<FirebaseError>] => {
  const { codes: userCampaigns } = useCampaigns();
  const [races, setRaces] = useState<RaceEventType[]>([]);
  const [loading, setLoading] = useState(true);
  const permissions = usePermissions();
  const userEventFilters = useUserEventFilters();

  const converter = useMemo(
    () => createRacingEventsConverter(userCampaigns, permissions),
    [userCampaigns],
  );

  const ref = collection(getFirestore(), "racingEvents").withConverter(
    converter,
  );

  const compiledQuery = useMemo(() => {
    const extraParams = getRacingEventsQueryForNextToGo(
      route,
      userEventFilters,
    );
    return query(ref, ...extraParams);
  }, [route, userEventFilters]);

  const [racesRaw, loadingRaw, error] = useCollectionData(
    compiledQuery,
    ref?.path,
  );

  useEffect(() => {
    if (!racesRaw || loadingRaw) {
      setLoading(true);
      return;
    }

    setRaces(racesRaw);
    setLoading(false);
  }, [racesRaw, loadingRaw]);

  return [races ?? [], loading, error];
};
