import { z } from "zod";
import type { infer as zInfer } from "zod/lib/types";
import { baseAutomation } from "hooks/firestore/v2/models/baseAutomation";

export const tokenAutomation = baseAutomation.extend({
  attributes: z
    .object({
      eventBasedTokens: z.boolean(),
    })
    .optional(),
});

export type TokenAutomation = zInfer<typeof tokenAutomation>;
