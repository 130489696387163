import React, { useMemo, useState } from "react";
import { zeroRightClassName } from "react-remove-scroll-bar";

import cx from "classnames";
import { useSelector, useDispatch, useDisplayAtLeast } from "hooks";
import { useProfile } from "hooks/firestore/v2/user/useProfile";
import { Icon, Link, BackButton, PageLoader, navigate } from "library";
import { NavLogo } from "./NavLogo";
import { selectUnfilteredSelectionsCount } from "sections/Betting/Betslip/betslipSlice";
import { selectPickemsNotificationCount } from "sections/Pickems/PickSlip/pickslipSlice";
import { useIsLoggedIn } from "hooks";
import { setModal, togglePanel } from "utilities/UI/uiSlice";
import { useCashAvailable } from "hooks/firestore";
import { useHomeUrl } from "hooks/firestore/v2/user/usePreferences";
import { useIsClient } from "usehooks-ts";
import { usePageContext } from "hooks/usePageContext";
import { useDisplayMode, useIsMobile } from "hooks";
import { clearToaster } from "hooks/ui/useToast";
import { useIsLoading } from "hooks/ui/useLoading";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/Popover/Popover";
import { ScrollArea } from "components/ScrollArea";

import { Notification } from "components/layout/Notification";
import { onDismissNotification } from "sections/Account/accountSlice";
import { ContextMenu } from "sections/Account/Tabs/Notifications/ContextMenu";
import { QuickBalance } from "components/layout/QuickBalance/QuickBalance";
import { Button } from "components/Button";
import { Badge } from "components/Badge";
import { LinkButton } from "components/LinkButton";
import { PerkIcon } from "components/layout/Perks";
import { useTokens } from "hooks/firestore/v2/user/useTokens";
import {
  useNotificationsCount,
  useNotifications,
} from "hooks/firestore/v2/user/useNotifications";
import { StatusMessage } from "components/layout/StatusMessage";
import { useHeaderOffset } from "hooks/useHeaderOffset";

import * as styles from "./Navigation.module.scss";

type NavPanelProps = {
  name: string;
  badge?: number;
  iconType?: string;
  className?: string;
};

const NavAuth = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useIsLoggedIn();
  const isClient = useIsClient();

  if (isLoggedIn || !isClient) {
    return null;
  }

  return (
    <div className={styles.auth}>
      <Button size={`sm`} onClick={() => dispatch(setModal("signUp"))}>
        Sign Up
      </Button>
      <Button
        size={`sm`}
        variant={`secondary`}
        onClick={() => dispatch(setModal("signIn"))}
      >
        Log In
      </Button>
    </div>
  );
};

const NavWallet = () => {
  const isLoggedIn = useIsLoggedIn();
  const { profile, isLoading } = useProfile();
  const { available, promo } = useCashAvailable();

  if (!isLoggedIn) {
    return <div className={styles.walletSpacing} />;
  }

  return (
    <Link to={`/wallet/deposit/`}>
      <QuickBalance
        loading={isLoading}
        available={available}
        promo={promo}
        name={profile?.username || ""}
        avatarSrc={profile?.avatarUri}
      />
    </Link>
  );
};

const NavPanels = () => {
  const isLoggedIn = useIsLoggedIn();
  const notificationsCount = useNotificationsCount();
  const betCount = useSelector(selectUnfilteredSelectionsCount);
  const { section } = usePageContext();
  const pickemsNotificationCount = useSelector(selectPickemsNotificationCount);
  const { tokens } = useTokens(true);
  const tokensCount = useMemo(() => {
    return Object.values(tokens).reduce((acc, group) => {
      const allocationsInGroup = group.tokens.reduce((acc, token) => {
        acc += token.allocations.length;
        return acc;
      }, 0);

      acc += allocationsInGroup;

      return acc;
    }, 0);
  }, [tokens]);

  return (
    <div className={styles.panels}>
      {isLoggedIn ? (
        <>
          <NotificationPanel badge={notificationsCount} />
          {tokensCount > 0 ? <PerksPanel badge={tokensCount} /> : null}
        </>
      ) : null}
      <NavPanel name={`search`} iconType={`search`} />
      {section === "pickems" ? (
        <NavPanel
          name={`pickslip`}
          iconType={`pick`}
          badge={pickemsNotificationCount}
        />
      ) : null}
      <NavPanel
        className={styles.hideOnMobile}
        name={`betslip`}
        iconType={`betslip`}
        badge={betCount}
      />
    </div>
  );
};

const PerksPanel = ({ badge }: { badge: number }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { tokens } = useTokens();

  return (
    <div
      className={cx(styles.panel, {
        [styles.active]: isOpen,
      })}
    >
      <Popover open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
        <PopoverTrigger className={styles.panelButton}>
          <Icon type={`star`} />
          {badge ? (
            <div className={styles.badge}>{badge >= 10 ? "9+" : badge}</div>
          ) : null}
        </PopoverTrigger>

        <PopoverContent
          sideOffset={20}
          className={cx(styles.panelPopover, styles.perksPopover)}
        >
          <div className={styles.perksContainer}>
            <div className={styles.perks}>
              {Object.keys(tokens).map((type) => {
                const group = tokens[type];
                return <Perk key={`perk-${type}`} group={group} type={type} />;
              })}
            </div>

            <div className={styles.readMoreContainer}>
              <LinkButton
                className={styles.button}
                variant={`blurred`}
                size={`sm`}
                to={`/perks/`}
              >
                View Perks
              </LinkButton>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const Perk = ({ group, type }: { group: any; type: any }) => {
  const count = useMemo(
    () =>
      group.tokens.reduce((acc, token) => {
        acc += token.allocations.length;
        return acc;
      }, 0),
    [group],
  );

  return (
    <div className={styles.perk}>
      <div className={styles.icon}>
        <PerkIcon type={type} />
        {count > 0 ? (
          <Badge className={styles.badge} text={`${count}`} />
        ) : null}
      </div>
      <div className={styles.name}>{group.name}</div>
    </div>
  );
};

const NotificationPanel = ({ badge }: { badge?: number }) => {
  const { notifications, isLoading } = useNotifications(1, 10);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();

  return (
    <div
      className={cx(styles.panel, {
        [styles.active]: isOpen,
      })}
      // on mobile just send user to the notifications page
      onClick={() => isMobile && navigate("/account/notifications")}
    >
      <Popover
        open={isOpen}
        onOpenChange={(isOpen) => setIsOpen(isMobile ? false : isOpen)}
      >
        <PopoverTrigger className={styles.panelButton}>
          <Icon type={`bell`} />
          {badge ? (
            <div className={styles.badge}>{badge >= 10 ? "9+" : badge}</div>
          ) : null}
        </PopoverTrigger>

        <PopoverContent sideOffset={20} className={styles.panelPopover}>
          <div className={styles.notificationsHeader}>
            <h4>Notifications</h4>
            <ContextMenu />
          </div>

          <ScrollArea>
            <div className={styles.notifications}>
              {isLoading ? (
                <PageLoader />
              ) : (
                <>
                  {notifications.map((notification) => (
                    <Notification
                      key={notification.id}
                      notification={notification}
                      onDismissNotification={(id) =>
                        dispatch(onDismissNotification(id))
                      }
                    />
                  ))}
                </>
              )}
            </div>
          </ScrollArea>
        </PopoverContent>
      </Popover>
    </div>
  );
};

const NavPanel = ({ name, iconType, badge, className = "" }: NavPanelProps) => {
  const isClient = useIsClient();
  const dispatch = useDispatch();
  const activePanel = useSelector((state: any) => state.utilities.ui.panel);
  const menu = useSelector((state: any) => state.utilities.ui.menu);
  const displayMode = useDisplayMode();
  const atLeastDesktopXl = useDisplayAtLeast("desktopXl");

  // make sure we don't set any panels as active on SSR
  const panelsVisible = isClient ? atLeastDesktopXl || menu === "right" : false;

  return (
    <div
      key={displayMode}
      className={cx(
        styles.panel,
        {
          [styles.active]: panelsVisible && activePanel === name,
        },
        className,
      )}
      onClick={() => {
        clearToaster();
        dispatch(togglePanel(name as any));
      }}
    >
      <Icon type={iconType} />
      {badge ? (
        <div className={styles.badge}>{badge >= 10 ? "9+" : badge}</div>
      ) : null}
    </div>
  );
};
export const Navigation = () => {
  const isMobile = useIsMobile();
  const isClient = useIsClient();
  const topOffset = useHeaderOffset();
  const isLoading = useIsLoading();
  const homeUrl = useHomeUrl();

  return (
    <>
      <StatusMessage />
      <header
        className={cx(styles.container, zeroRightClassName)}
        style={{ top: topOffset }}
      >
        <BackButton animatePresence={isMobile && isClient} />
        <Link to={homeUrl}>
          <NavLogo loading={isLoading} />
        </Link>

        <div className={styles.right}>
          <NavAuth />
          <NavPanels />
          <NavWallet />
        </div>
      </header>
    </>
  );
};
