import { useMemo, useContext } from "react";
import { useSkrillDeposits } from "hooks";
import { useProfile } from "hooks/firestore/v2/user/useProfile";

const useSkrillEmails = (): [string[], boolean] => {
  const [deposits, depositsLoading] = useSkrillDeposits();
  const { profile } = useProfile();

  const skrillEmails = useMemo(() => {
    if (!profile || !deposits) {
      return [];
    }

    return [
      ...new Set([
        profile.email,
        ...deposits.map((deposit: any) => deposit.skrillEmail),
      ]),
    ].filter(Boolean);
  }, [profile?.email, deposits]);

  return [skrillEmails, !profile || depositsLoading];
};

export default useSkrillEmails;
