import { z, type infer as zInfer } from "zod";

export const user = z.object({
  entryCounts: z
    .object({
      HISTORICAL: z.number().nullish(),
      LIVE: z.number().nullish(),
      UPCOMING: z.number().nullish(),
    })
    .nullish(),
  payId: z
    .object({
      id: z.string(),
    })
    .nullish(),
  profile: z.object({
    avatarUri: z.string().nullish(),
    clientNumber: z.number(),
    cooldownEndTime: z.string().optional(),
    country: z.string(),
    createdAt: z.string(),
    dateOfBirth: z.string(),
    email: z.string(),
    emailVerificationStatus: z.enum(["VERIFIED", "UNVERIFIED", "NOT_REQUIRED"]),
    fiatCurrency: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    mfa: z.string(),
    migration: z.number(),
    phone: z.string(),
    postCode: z.string(),
    returningNSER: z.boolean(),
    state: z.string(),
    status: z.enum(["ACTIVE", "LOCKED", "CLOSED", "DORMANT"]),
    street: z.string(),
    streetNumber: z.string(),
    suburb: z.string(),
    unit: z.string().optional(),
    updatedAt: z.string(),
    userId: z.string(),
    username: z.string(),
    verificationStatus: z.enum([
      "UNVERIFIED",
      "IN_PROGRESS",
      "PENDING_REVIEW",
      "VERIFIED",
      "VERIFIED_ADMIN",
      "ERROR_OCCURRED",
      "FAILED",
    ]),
  }),
  promotionCode: z
    .object({
      active: z.boolean(),
      claimerAmount: z.number(),
      code: z.string(),
      currency: z.string(),
      ownerAmount: z.number(),
    })
    .optional(),
  settings: z
    .object({
      acceptOdds: z.enum(["MANUAL", "AUTO", "AUTO_HIGHER"]).nullish(),
      allowCancelWithdrawal: z.boolean().nullish(),
      defaultHub: z.enum(["SPORTS", "ESPORTS", "RACING"]).nullish(),
      id: z.string(),
      oddsFormat: z.enum(["DECIMAL", "AMERICAN", "FRACTIONAL"]).nullish(),
      userId: z.string().nullish(),
    })
    .nullish(),
});

export type User = zInfer<typeof user>;
