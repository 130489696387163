import { z } from "zod";
import type { infer as zInfer } from "zod/lib/types";

export const creditCard = z.object({
  id: z.string(),
  active: z.boolean(),
  cardType: z.literal("CREDIT_CARD"), // this will never be anything else
  createdAt: z.string(),
  expiryMonth: z.string(),
  expiryYear: z.string(),
  name: z.string(),
  numberFirst: z.string(),
  numberLast: z.string(),
  successfulDepositCount: z.number(),
  userId: z.string(),
  lastSuccessfulDepositAt: z.string().optional(),
});

export type CreditCard = zInfer<typeof creditCard>;
