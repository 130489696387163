import { useMemo } from "react";
import { getFirestore } from "store/getFirebase";
import { useCollectionData, usePermissions } from "hooks";
import { endOfDay, startOfDay } from "date-fns";
import {
  collection,
  query,
  where,
  type FirestoreError,
  orderBy,
  limit,
} from "firebase/firestore";
import { NEXT_TO_GO_LIMIT } from "./useRacingEvents";
import {
  type RaceMeetingType,
  createRaceMeetingsConverter,
} from "sections/Betting/Race/hooks/useRaceMeetings";
import { useUserEventFilters } from "hooks/firestore/useUserAttributes";
import { useCampaigns } from "hooks/firestore/v2/user/useCampaigns";
import type { Maybe } from "types/utilities";

export const useMinimalRaceMeetings = (
  date: Date,
  region: string,
  isNextToGo: boolean,
): [RaceMeetingType[], boolean, Maybe<FirestoreError>] => {
  const { codes: userCampaigns } = useCampaigns();
  const permissions = usePermissions();
  const userEventFilters = useUserEventFilters();

  const raceMeetingsConverter = useMemo(
    () => createRaceMeetingsConverter(userCampaigns, permissions),
    [userCampaigns],
  );

  const ref = collection(getFirestore(), "raceMeetings").withConverter(
    raceMeetingsConverter,
  );

  const queries = [];
  queries.push(
    where("region", "==", region),
    where("visible", "==", true),
    where("currentRace.visible", "==", true),
  );

  if (isNextToGo) {
    queries.push(where("currentRace.status", "==", "OPEN"));
    queries.push(orderBy("currentRace.scheduledStartTimeTs", "asc"));
    queries.push(limit(NEXT_TO_GO_LIMIT));
  } else {
    queries.push(where("scheduledStartTimeTs", ">=", startOfDay(date)));
    queries.push(where("scheduledStartTimeTs", "<=", endOfDay(date)));
  }

  if (userEventFilters?.length > 0) {
    queries.push(where("filters", "array-contains-any", userEventFilters));
  }

  const [raceMeetings, loading, error] = useCollectionData(
    query(ref, ...queries),
    ref?.path,
  );

  return [raceMeetings, loading, error];
};
