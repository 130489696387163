// extracted by mini-css-extract-plugin
export var action = "Toast-module--action--4e47e";
export var actions = "Toast-module--actions--d75af";
export var blue = "#0eb3f2";
export var close = "Toast-module--close--30319";
export var danger = "Toast-module--danger--f8bb1";
export var description = "Toast-module--description--be89b";
export var emphasis = "Toast-module--emphasis--71a8a";
export var focus = "Toast-module--focus--ec7c1";
export var green = "#57d33b";
export var greenLink = "Toast-module--green-link--200e5";
export var grey = "var(--tone-mid)";
export var hasModal = "Toast-module--hasModal--054c1";
export var hasTipsOnly = "Toast-module--hasTipsOnly--92b93";
export var icon = "Toast-module--icon--bfa05";
export var info = "Toast-module--info--ad1b3";
export var notchGap = "32px";
export var orange = "#f8662b";
export var promotion = "Toast-module--promotion--4509a";
export var red = "#ff2741";
export var success = "Toast-module--success--abe00";
export var tips = "Toast-module--tips--18eb7";
export var title = "Toast-module--title--0f168";
export var titleAndDescription = "Toast-module--titleAndDescription--d1ca3";
export var toast = "Toast-module--toast--11490";
export var viewport = "Toast-module--viewport--194a1";
export var warning = "Toast-module--warning--d9688";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";