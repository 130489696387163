import React, { Suspense, lazy } from "react";
import cx from "classnames";
import Odds from "sections/Betting/Odds";
import { ReactComponent as PlaceMedals1 } from "sections/Betting/Race/components/assets/icon-medal1.svg";
import { ReactComponent as PlaceMedals2 } from "sections/Betting/Race/components/assets/icon-medal2.svg";
import { ReactComponent as PlaceMedals3 } from "sections/Betting/Race/components/assets/icon-medal3.svg";
import { ReactComponent as PlaceMedals4 } from "sections/Betting/Race/components/assets/icon-medal4.svg";
import { ReactComponent as RaceError } from "components/assets/racing-error.svg";
import {
  RaceMarketTypeTypes,
  type RaceOutcomeType,
  RaceStatuses,
} from "sections/Betting/Race/hooks/RacingTypes";
import { sameRaceMultiLabelMap } from "../Top4/Top4";
import { RaceMessage, Silks } from "sections/Betting/Race/components";
import type { RouteComponentProps } from "@gatsbyjs/reach-router";
import * as styles from "./RaceResult.module.scss";
import { CompetitorNumberAndName } from "sections/Betting/Race/components/CompetitorInfo/CompetitorInfo";
import { Alert } from "components/Alert";
import { useRaceResult } from "./hooks/useRaceResult";
import { SupportLink } from "components/SupportLink";
import { RaceResultSkeleton } from "sections/Betting/Race/components/RaceResult/RaceResultSkeleton";
import { getJockeyLabel } from "utilities/racingUtilities";
import { PageMessage } from "components/PageMessage";
import { ButtonGroup } from "components/ButtonGroup";
import { LinkButton } from "components/LinkButton";
import { useIsMobile } from "hooks";
const RacingAnimation = lazy(
  () => import("sections/Betting/Race/components/RacingAnimation"),
);

type MappedCompetitor = {
  markets: {
    marketType: RaceMarketTypeTypes;
    outcomeId: string;
    outcome: Partial<RaceOutcomeType>;
    marketId: string;
  }[];
  resultPosition: any;
  id: string;
  number: number;
  name: string;
  jockey: string;
  trainer: string;
  weight: string;
  age: string;
  comment: string;
  startingPosition: string;
  formAttributes: Record<string, string>;
  sex: "G|M|F";
  scratchedAt?: Date;
  scratched?: boolean;
  winDeduction?: string;
  placeDeduction?: string;
  deductions?: Record<RaceMarketTypeTypes, number>;
};

const getPositionEmoji = (position: number) => {
  switch (position) {
    case 1:
      return (
        <div>
          <PlaceMedals1 />
        </div>
      );
    case 2:
      return (
        <div>
          <PlaceMedals2 />
        </div>
      );
    case 3:
      return (
        <div>
          <PlaceMedals3 />
        </div>
      );
    case 4:
      return (
        <div>
          <PlaceMedals4 />
        </div>
      );
    default:
      return null;
  }
};

export default (_: RouteComponentProps) => {
  const { sortedCompetitors, hasDeadHeat, userPreferences, race, loading } =
    useRaceResult();
  const isMobile = useIsMobile();

  if (loading) {
    return <RaceResultSkeleton />;
  }

  if (race?.status === RaceStatuses.ABANDONED) {
    return (
      <PageMessage
        title="Race Abandoned"
        image={<RaceError />}
        subTitle="Bets on the race have been refunded"
      >
        <ButtonGroup>
          <LinkButton
            variant={`primary`}
            size={isMobile ? `md` : `default`}
            to="/racing/betting/"
          >
            Next to Jump
          </LinkButton>
        </ButtonGroup>
      </PageMessage>
    );
  }

  if (Object.keys(race?.results || {}).length === 0) {
    return (
      <Suspense fallback={null}>
        <RacingAnimation sport={race?.sport} />
      </Suspense>
    );
  }

  return (
    <div className={cx(styles.resultsContainer)}>
      {sortedCompetitors.map((competitor) => (
        <div
          className={cx(styles.box, {
            [styles.firstPlace]: competitor.resultPosition === 1,
            [styles.secondPlace]: competitor.resultPosition === 2,
            [styles.thirdPlace]: competitor.resultPosition === 3,
            [styles.fourthPlace]: competitor.resultPosition === 4,
          })}
          key={race?.number + competitor?.id}
        >
          <div className={styles.winnerInfo}>
            <div>
              {race.silksUrl ? (
                <div>
                  <Silks
                    silksUrl={race?.silksUrl}
                    competitorNumber={Number(competitor?.number)}
                    imageHeight={40}
                    key={competitor.id}
                  />
                </div>
              ) : null}
            </div>
            <div className={styles.winnerName}>
              <CompetitorNumberAndName
                competitor={competitor as any}
                race={race}
              />
              {competitor.jockey && (
                <div className={styles.secondaryInformation}>
                  {getJockeyLabel(competitor?.jockey, race?.sport)}
                </div>
              )}
            </div>
            <div className={cx(styles.medal)}>
              {getPositionEmoji(competitor.resultPosition)}
            </div>
          </div>

          <ResultOutcome
            competitor={competitor}
            oddsFormat={userPreferences.oddsFormat.toLowerCase()}
          />
        </div>
      ))}
      <RaceMessage />
      {hasDeadHeat && (
        <Alert
          variant="info"
          indicator="top"
          size="small"
          className={styles.deadHeat}
        >
          <span>
            2 or more runners were involved in a{" "}
            <SupportLink hideChevron options={{ articleId: "4412015626255" }}>
              Dead Heat
            </SupportLink>
          </span>
        </Alert>
      )}
    </div>
  );
};

const ResultOutcome = ({
  competitor,
  oddsFormat,
}: {
  competitor: MappedCompetitor;
  oddsFormat: any;
}) => {
  // Define the desired outcomes
  const desiredOutcomes = [
    RaceMarketTypeTypes.Win,
    RaceMarketTypeTypes.Top2,
    RaceMarketTypeTypes.Top3,
    RaceMarketTypeTypes.Top4,
  ];

  // Find the place market
  const placeMarket = competitor?.markets.find(
    (market) =>
      market.marketType === RaceMarketTypeTypes.Place &&
      market.outcome.result === "WIN",
  );

  return (
    <div
      className={cx(styles.resultOutcomes, {
        [styles.firstPlace]: competitor.resultPosition === 1,
        [styles.secondPlace]: competitor.resultPosition === 2,
        [styles.thirdPlace]: competitor.resultPosition === 3,
        [styles.fourthPlace]: competitor.resultPosition === 4,
      })}
    >
      {desiredOutcomes.map((desiredOutcome, index) => {
        const result = competitor.markets.find(
          ({ marketType }) => marketType === desiredOutcome,
        );

        const isSameAsPlace =
          placeMarket?.outcome?.type === result?.outcome?.type;
        const finalLabel = result?.outcome?.type
          ? `${sameRaceMultiLabelMap[result?.outcome.type]} ${
              isSameAsPlace ? "(P)" : ""
            }`
          : "";

        return (
          <div
            key={index}
            className={cx(styles.resultOutcome, {
              [styles.firstPlace]: competitor.resultPosition === 1,
              [styles.secondPlace]: competitor.resultPosition === 2,
              [styles.thirdPlace]: competitor.resultPosition === 3,
              [styles.fourthPlace]: competitor.resultPosition === 4,
            })}
          >
            <div className={styles.label}>{finalLabel}</div>
            <div className={styles.odds}>
              {result?.outcome && result?.outcome.active ? (
                <Odds
                  base={Number(result?.outcome.odds)}
                  zero={`-`}
                  format={oddsFormat}
                />
              ) : (
                "-"
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};
