import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import { combinations as calculateCombinations } from "mathjs";
import type {
  SourceType,
  ToggleSelectionPayload,
} from "hooks/firestore/betting/types";
import type { Profile } from "hooks/firestore/user/types";
import type { Event, EventMarket } from "hooks/firestore/betting/useBetting";
import { countBy, isMatchWith, has } from "lodash";
import memoize from "lodash/memoize";
import { BettingTransforms } from "selectors/BettingTransforms";
import type {
  BetSelectionType,
  betslipHubType,
  CurrencyType,
  MarketStatus,
  OddsFormat,
} from "types/BetTypes";
import { isDisplayMode } from "utilities/display";
import { parseError } from "utilities/errorsParser";
import localObject from "utilities/localObject";
import Message from "utilities/message/legacy";
import { duplicateBetAttempted, setMenu, setPanel } from "utilities/UI/uiSlice";
import { BET_ADDED_TO_SLIP, BET_SUBMITTED } from "../BettingConfig";
import { differenceInSeconds, parseISO } from "date-fns";
import type { RootState } from "store/createStore";
import BigNumber from "bignumber.js";
import { getBettingChannel } from "utilities/bettingChannels";
import { getIsSRMAvailable } from "utilities/racingUtilities";
import {
  type AuthState,
  type Permission,
  PermissionReason,
} from "utilities/Auth/authSlice";
import { toast } from "hooks/ui/useToast";
import { isMarketOpenForBets } from "utilities/markets";
import { v4 as uuidv4 } from "uuid";
import {
  addToAccumulator,
  calculateExoticCombinations,
  calculateQuinellaCombinations,
  exoticPositionFromOutcomeMap,
  isExoticMarketType,
} from "../Race/components/Exotics/Exotics.utils";
import {
  calculatePotentialPayout,
  getCurrentOdds,
  rounded,
  safeExoticStake,
} from "utilities/sharedBettingUtilities";
import type { SubOutcome } from "sections/Entries/types";
import { selectContestPicks } from "sections/Pickems/PickSlip/pickslipSlice";

const KEY_LAST_BETSLIP = "pb-last-betslip-submitted";
const TIMEOUT_PADDING = 5; // seconds

export enum BetslipUIStates {
  active = "ACTIVE",
  confirming = "CONFIRMING",
  submitting = "SUBMITTING",
  intercepted = "INTERCEPTED",
  duplicate = "DUPLICATE",
  acceptDuplicate = "ACCEPT_DUPLICATE",
  quickDepositing = "QUICK_DEPOSIT",
}

export enum BetType {
  Multi = "MULTI",
  Single = "SINGLE",
  Pending = "PENDING",
  Settled = "SETTLED",
}

export type BetSlipSelectionSummary = {
  eventId: string;
  marketId: string;
  outcomeId: string;
  competitorId: string;
  stake?: number;
  scheduledStartTime?: string;
  sourceType: SourceType;
  eventName?: string;
  marketName?: string;
  competitorName?: string;
  outcomeName?: string;
  subOutcomes?: {
    outcomeId: string;
    outcomeName: string;
  }[];
};

export type BetslipState = {
  betType: BetType;
  selections: Record<string, BetSelectionType>;
  oddsFormat: OddsFormat;
  multiStake: number;
  isMultiUsingPromo: boolean;
  canUsePromoMulti: boolean;
  multiAccepted: string | null;
  betslipUIState: BetslipUIStates;
  stakeAll: number;
  messageId: string | null;
  betslipMessage: string;
  multiAlternativeStakeReference: string | null;
  usedSelections: string[];
  multiRejected: {
    reason: string;
    message: string;
    alternativeStake?: number;
  } | null;
  stakePerCombination: Record<
    string,
    {
      total: number;
      combo: number;
      isPromo: boolean;
      correlationId: string;
      betId?: string;
      entryId?: number;
      rejected?: {
        reason: string;
        message: string;
        alternativeStake?: number;
      };
    }
  >;
  betId: string;
  multiTokenAllocationId: string;
};

const initialState: BetslipState = {
  betType: BetType.Single,
  selections: {},
  oddsFormat: "decimal" as OddsFormat,
  multiStake: 0,
  isMultiUsingPromo: false,
  canUsePromoMulti: false,
  multiAccepted: null as string,
  betslipUIState: "ACTIVE" as BetslipUIStates,
  stakeAll: 0,
  messageId: null as string,
  betslipMessage: "",
  multiAlternativeStakeReference: null as string,
  usedSelections: [] as string[],
  multiRejected: null,
  stakePerCombination: {},
  betId: undefined,
  multiTokenAllocationId: null,
};

type SharedEntryTracking = {
  url: string;
  date: Date;
  name: string;
  hideStake: boolean;
  referenceId: string;
};

const parseStake = (stake: string | number) => {
  const parsedStake = new BigNumber(stake).multipliedBy(100);
  return parsedStake.isNaN() ? 0 : parsedStake.toNumber();
};

// #region Actions

const betslipSlice = createSlice({
  name: "Betslip",
  initialState,
  reducers: {
    setBetType(state, action: PayloadAction<BetType>) {
      // Don't allow tab change if a bet is being submitted
      if (state.betslipUIState === BetslipUIStates.submitting) {
        return;
      }

      // Changing from Multi to any other tab
      if (state.betType === BetType.Multi) {
        if (state.multiAccepted) {
          delete state.multiAccepted;
          state.selections = {};
        }
        if (state.multiRejected) {
          delete state.multiRejected;
        }
        if (Object.values(state?.selections)?.[0]?.rejected) {
          Object.values(state.selections).forEach(
            (selection) => delete selection.rejected,
          );
        }
      }

      // Changing from Single to any other tab
      if (state.betType === BetType.Single) {
        const hasPlacedBets = Object.values(state.selections).some(
          (curr) => curr.betId || curr.rejected,
        );

        if (hasPlacedBets) {
          state.selections = {};
        }
      }

      state.stakePerCombination = {};
      state.isMultiUsingPromo = false;
      state.multiStake = 0;
      state.betType = action.payload;
      state.betslipUIState = BetslipUIStates.active;
      state.stakeAll = 0;
      betslipSlice.caseReducers.clearBetslipMessage(state);
    },
    setStakeSingle(
      state,
      action: PayloadAction<{
        outcomeId: string;
        stake: number;
      }>,
    ) {
      if (state.betslipUIState !== BetslipUIStates.quickDepositing) {
        state.betslipUIState = BetslipUIStates.active;
      }
      state.selections[action.payload.outcomeId].stake = action.payload.stake;
      delete state.selections[action.payload.outcomeId].rejected;
      state.stakeAll = 0;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      betslipSlice.caseReducers.clearAccepted(state, {
        type: action.type,
      });
    },
    clearRejectedCombo(state, action: PayloadAction<string>) {
      state.stakePerCombination[action.payload].rejected = null;
    },
    setCombinationPerComboStake(
      state,
      action: PayloadAction<{
        type: string; // type of combination 2, 3, 4 etc
        stake: number; // total stake for type of combination
        combinations: number; // number of possible combinations
      }>,
    ) {
      if (state.betslipUIState !== BetslipUIStates.quickDepositing) {
        state.betslipUIState = BetslipUIStates.active;
      }

      const totalStake = BigNumber(action.payload.stake)
        .multipliedBy(action.payload.combinations)
        .dp(2, BigNumber.ROUND_FLOOR)
        .toNumber();

      const correlationId =
        state.stakePerCombination[action.payload.type]?.correlationId ||
        uuidv4();

      const isPromo = !!state.stakePerCombination[action.payload.type]?.isPromo;

      state.stakePerCombination[action.payload.type] = {
        ...state.stakePerCombination[action.payload.type],
        total: totalStake,
        combo: action.payload.stake,
        correlationId,
        isPromo,
      };
    },
    setCombinationTotalStake(
      state,
      action: PayloadAction<{
        type: string; // type of combination 2, 3, 4 etc
        stake: number; // total stake for type of combination
        combinations: number; // number of possible combinations
      }>,
    ) {
      if (state.betslipUIState !== BetslipUIStates.quickDepositing) {
        state.betslipUIState = BetslipUIStates.active;
      }

      // calculate per combo stake
      const perCombo = BigNumber(action.payload.stake)
        .dividedBy(action.payload.combinations)
        .dp(2, BigNumber.ROUND_FLOOR)
        .toNumber();

      const correlationId =
        state.stakePerCombination[action.payload.type]?.correlationId ||
        uuidv4();

      const isPromo = !!state.stakePerCombination[action.payload.type]?.isPromo;

      state.stakePerCombination[action.payload.type] = {
        total: action.payload.stake,
        combo: perCombo,
        correlationId,
        isPromo,
      };
    },
    syncCombinationsTotalStake(
      state,
      action: PayloadAction<{
        type: string;
        combinations: number;
      }>,
    ) {
      const perCombo = state.stakePerCombination[action.payload.type]?.combo;
      const total = state.stakePerCombination[action.payload.type]?.total;

      if (typeof perCombo === "undefined") return;

      if (total > 0 && perCombo === 0) {
        // if total is greater than 0 and perCombo is 0, we have a value that is less than a cent
        // we need to reset total stake to a minimum value of 1 cent

        state.stakePerCombination[action.payload.type].total = BigNumber(
          action.payload.combinations,
        )
          .dividedBy(100)
          .dp(2, BigNumber.ROUND_FLOOR)
          .toNumber(); // cent per combination
        state.stakePerCombination[action.payload.type].combo = 0.01;
        return;
      }

      state.stakePerCombination[action.payload.type].total = BigNumber(perCombo)
        .multipliedBy(action.payload.combinations)
        .dp(2, BigNumber.ROUND_FLOOR)
        .toNumber();
    },
    setIsCombinationUsingPromo(
      state,
      action: PayloadAction<{
        type: string;
        active: boolean;
      }>,
    ) {
      if (
        typeof state.stakePerCombination[action.payload.type] === "undefined"
      ) {
        state.stakePerCombination[action.payload.type] = {
          total: 0,
          combo: 0,
          isPromo: false,
          correlationId: uuidv4(),
        };
      }

      state.betslipUIState = BetslipUIStates.active;

      state.stakePerCombination[action.payload.type].isPromo =
        action.payload.active;
    },
    setIsUsingPromo(
      state,
      action: PayloadAction<{ outcomeId: string; active: boolean }>,
    ) {
      state.betslipUIState = BetslipUIStates.active;
      state.selections[action.payload.outcomeId].isUsingPromo =
        action.payload.active;
      state.selections[action.payload.outcomeId].allocationId = null;
    },
    setTokenAllocationId(
      state,
      action: PayloadAction<{ outcomeId: string; allocationId: string }>,
    ) {
      state.betslipUIState = BetslipUIStates.active;

      // is allocationId already being used?
      // if so, deselect it
      const selectionIdWithGivenAllocationId = Object.keys(
        state.selections,
      ).find(
        (selectionId) =>
          state.selections[selectionId].allocationId ===
          action.payload.allocationId,
      );

      if (selectionIdWithGivenAllocationId) {
        delete state.selections[selectionIdWithGivenAllocationId].allocationId;
      }

      state.selections[action.payload.outcomeId].allocationId =
        action.payload.allocationId;
    },
    updateBetslip(state, action: PayloadAction<Partial<BetslipState>>) {
      const payload = action.payload;
      state.stakeAll = 0;
      Object.keys(action.payload).forEach((key) => {
        if (key === "selections") return; // Selections changes should be set through updateSelection
        state[key] = payload[key];
      });
    },
    updateSelection(
      state,
      action: PayloadAction<Partial<BetSelectionType> & { outcomeId: string }>,
    ) {
      state.stakeAll = 0;
      state.selections[action.payload.outcomeId] = {
        ...state.selections[action.payload.outcomeId],
        ...action.payload,
      };
    },
    acceptCombination(
      state: BetslipState,
      action: PayloadAction<{
        type: string;
        betId: string;
        entryId: number;
      }>,
    ) {
      state.stakePerCombination[action.payload.type].betId =
        action.payload.betId;
      state.stakePerCombination[action.payload.type].entryId =
        action.payload.entryId;
    },
    rejectCombination(
      state: BetslipState,
      action: PayloadAction<{
        type: string;
        reason: string;
        message: string;
        alternativeStake?: number;
      }>,
    ) {
      state.stakePerCombination[action.payload.type].rejected = {
        reason: action.payload.reason,
        message: action.payload.message,
        alternativeStake: action.payload.alternativeStake,
      };
    },
    setStakeMulti(
      state,
      action: PayloadAction<{
        stake: number;
      }>,
    ) {
      if (state.betslipUIState !== BetslipUIStates.quickDepositing) {
        state.betslipUIState = BetslipUIStates.active;
      }
      state.multiStake = action.payload.stake;
      delete state.multiAccepted;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      betslipSlice.caseReducers.clearRejected(state, {
        type: action.type,
      });
    },
    setBetslipMessage: (state, action: PayloadAction<string>) => {
      state.betslipMessage = action.payload;
    },
    clearBetslipMessage: (state) => {
      state.betslipMessage = "";
    },
    setMultiIsUsingPromo(state, action: PayloadAction<boolean>) {
      state.betslipUIState = BetslipUIStates.active;
      state.isMultiUsingPromo = action.payload;
    },
    setMultiTokenAllocationId(state, action: PayloadAction<string>) {
      state.multiTokenAllocationId = action.payload;
    },
    addSelection(state, action: PayloadAction<BetSelectionType>) {
      if (
        state.betslipUIState === BetslipUIStates.submitting ||
        state.betslipUIState === BetslipUIStates.intercepted
      ) {
        return;
      }

      const hasPlacedBets =
        Object.values(state.selections).some(
          (curr) => curr.betId || curr.rejected,
        ) ||
        Object.values(state.stakePerCombination).some((combo) => combo.betId) ||
        state.multiAccepted ||
        state.multiRejected;

      // We clear selections by default.
      if (hasPlacedBets) {
        state.selections = {};
        state.multiStake = 0;
        state.isMultiUsingPromo = false;
        state.stakePerCombination = {};
        delete state.multiAccepted;
        delete state.multiRejected;
      }

      // If we are in multi and we are adding an outright and promo cash is toggled,
      // We clear the promo
      if (
        state.betType === BetType.Multi &&
        state.isMultiUsingPromo &&
        action.payload.sourceType === "outright"
      ) {
        state.isMultiUsingPromo = false;
        state.multiStake = 0;
      }

      state.stakeAll = 0;
      state.betslipUIState = BetslipUIStates.active;
      state.selections[action.payload.outcomeId] = action.payload;
      state.stakePerCombination = {};

      const selectionKeys = Object.keys(state.selections);

      const isMultiPreferred =
        // We check that a valid multi can actually be created. i.e. no related markets etc
        // Rules are from selectHasRelated shared logic.
        !selectionKeys.some((selectionOutcomeId) =>
          selectHasRelated({ betslip: state })(selectionOutcomeId),
        ) &&
        // We only want to set multi if we have more than one selection
        selectionKeys.length > 1 &&
        // We only want to set multi if all selections are active. Else you are blocked from placing a multi anyway
        selectionKeys.every((selectionId) =>
          isMarketOpenForBets(state.selections[selectionId].changes?.status),
        );

      if (isMultiPreferred) {
        state.betType = BetType.Multi;
      } else {
        state.betType = BetType.Single;
      }
    },
    removeSelection(state, action: PayloadAction<string>) {
      if (
        state.betslipUIState === BetslipUIStates.submitting ||
        state.betslipUIState === BetslipUIStates.intercepted
      )
        return;
      state.stakeAll = 0;
      state.betslipUIState = BetslipUIStates.active;
      delete state.selections[action.payload];

      // if we are in multi clear rejected error
      if (state.betType === "MULTI") {
        delete state.multiRejected;
      }

      state.stakePerCombination = {};

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      betslipSlice.caseReducers.clearRejected(state, {
        type: action.type,
      });
    },
    clearRejected(state, action?: PayloadAction<string>) {
      state.stakeAll = 0;
      if (action.payload) {
        delete state.selections[action.payload].rejected;
      } else {
        for (const selection in state.selections) {
          if (state.selections[selection].rejected) {
            delete state.selections[selection].rejected;
          }
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      betslipSlice.caseReducers.clearAccepted(state, {
        type: action.type,
      });
    },
    removeRejected(state) {
      state.stakeAll = 0;
      for (const selection in state.selections) {
        if (state.selections[selection].rejected) {
          delete state.selections[selection];
        }
      }
      betslipSlice.caseReducers.clearBetslipMessage(state);
    },
    clearAccepted(state) {
      state.stakeAll = 0;
      if (state.betType === BetType.Multi && state.multiAccepted) {
        delete state.multiAccepted;
      }

      for (const selection in state.selections) {
        if (state.selections[selection].betId) {
          delete state.selections[selection].betId;
          if (state.betType === BetType.Single) {
            state.selections[selection].stake = 0;
          }
        }
      }

      betslipSlice.caseReducers.clearBetslipMessage(state);
      betslipSlice.caseReducers.clearIntercepted(state);
    },
    removeAccepted(state) {
      state.stakeAll = 0;
      if (state.betType === BetType.Multi && state.multiAccepted) {
        delete state.multiAccepted;
        for (const selection in state.selections) {
          if (state.selections[selection].betId) {
            delete state.selections[selection];
          }
        }
      } else if (state.betType === BetType.Single) {
        for (const selection in state.selections) {
          if (state.selections[selection].betId) {
            delete state.selections[selection];
          }
        }
      }
      betslipSlice.caseReducers.clearBetslipMessage(state);
    },
    clearIntercepted(state) {
      for (const selection in state.selections) {
        if (state.selections[selection].isIntercepted) {
          state.selections[selection].isIntercepted = false;
        }
      }
    },
    clearSelections(state) {
      if (state.betslipUIState === BetslipUIStates.submitting) {
        return;
      }

      state.multiStake = 0;
      state.stakeAll = 0;
      state.betslipUIState = BetslipUIStates.active;
      state.selections = {};
      delete state.multiAccepted;
      delete state.multiRejected;
      betslipSlice.caseReducers.clearBetslipMessage(state);
      state.usedSelections = [];
    },
    changeOdds(
      state,
      action: PayloadAction<{ outcomeId: string; odds?: number }>,
    ) {
      state.stakeAll = 0;
      if (state.betslipUIState !== BetslipUIStates.quickDepositing) {
        state.betslipUIState = BetslipUIStates.active;
      }

      state.selections[action.payload.outcomeId].odds =
        action.payload.odds ??
        state.selections[action.payload.outcomeId].changes.newOdds;
      delete state.selections[action.payload.outcomeId].changes.newOdds;
    },
    setPendingOddsChange(
      state,
      action: PayloadAction<{ outcomeId: string; newOdds?: number }>,
    ) {
      state.stakeAll = 0;
      if (state.betslipUIState !== BetslipUIStates.quickDepositing) {
        state.betslipUIState = BetslipUIStates.active;
      }
      state.selections[action.payload.outcomeId].changes.newOdds =
        action.payload.newOdds;
    },
    statusChangeOnSelection(
      state,
      action: PayloadAction<{ outcomeId: string; marketStatus: MarketStatus }>,
    ) {
      state.stakeAll = 0;
      if (state.betslipUIState !== BetslipUIStates.quickDepositing) {
        state.betslipUIState = BetslipUIStates.active;
      }
      if (state.selections && state.selections[action.payload.outcomeId]) {
        state.selections[action.payload.outcomeId].changes.status =
          action.payload.marketStatus;
      }
    },
    setBetSlipState(state, action: PayloadAction<BetslipUIStates>) {
      state.stakeAll = 0;
      state.betslipUIState = action.payload;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      betslipSlice.caseReducers.clearRejected(state, {
        type: action.type,
      });
    },
    reuseSelection(state, _action: PayloadAction<null>) {
      state.betslipUIState = BetslipUIStates.active;
      delete state.multiAccepted;
      state.multiStake = 0;
      state.isMultiUsingPromo = false;
      state.stakePerCombination = {};
      Object.keys(state.selections).forEach((outcomeId) => {
        state.selections[outcomeId].stake = 0;
        state.selections[outcomeId].isUsingPromo = false;
        delete state.selections[outcomeId].betId;
        delete state.selections[outcomeId].rejected;

        // We auto accept odds changes on reuse selections

        if (state.selections[outcomeId].changes.newOdds) {
          state.selections[outcomeId].odds =
            state.selections[outcomeId].changes.newOdds;
          delete state.selections[outcomeId].changes.newOdds;
        }
      });

      betslipSlice.caseReducers.clearBetslipMessage(state);
    },
    updateStake(state, _action: PayloadAction<null>) {
      state.betslipUIState = BetslipUIStates.active;
      Object.keys(state.selections).forEach((outcomeId) => {
        state.selections[outcomeId].stake = 0;
        state.selections[outcomeId].isUsingPromo = false;
        delete state.selections[outcomeId].betId;
      });
    },
    setStakeAllSingles(
      state,
      action: PayloadAction<{
        stake: number;
        userFilters: string[];
      }>,
    ) {
      state.stakeAll = action.payload.stake;
      state.betslipUIState = BetslipUIStates.active;
      // get attributes from store
      // this is the only way we can get them in a reducer unfortunately
      const userFilters = action.payload.userFilters;

      Object.keys(state.selections).forEach((outcomeId) => {
        if (selectionCanBeCounted(state.selections[outcomeId], userFilters)) {
          state.selections[outcomeId].stake = action.payload.stake;

          const isExotic = has(
            state.selections[outcomeId],
            "exoticCombinations",
          );

          if (isExotic) {
            state.selections[outcomeId].stake = safeExoticStake(
              action.payload.stake,
              state.selections[outcomeId].exoticCombinations,
            );
          }

          state.selections[outcomeId].isUsingPromo = false;
          delete state.selections[outcomeId].rejected;
        }
      });
      betslipSlice.caseReducers.clearBetslipMessage(state);
    },
    convertSelectionsToSRM(state, action: PayloadAction<string>) {
      const baseSelection = Object.values(state.selections || {})?.[0];
      const selections = { ...state.selections };
      const outcomeId = action.payload;
      state.selections = {};
      state.selections[outcomeId] = {
        ...baseSelection,
        market: baseSelection.eventName,
        selectionName: baseSelection.eventName,
        eventName: `Same Race Multi (${
          Object.keys(selections || {}).length
        } legs)`,
        outcomeId,
        alternativeStakeReference: null,
        odds: 0,
        stake: 0,
        changes: {
          status: "ACTIVE",
        },
        outcome: null,
        subOutcomes: Object.entries(selections || {}).map(
          ([outcomeId, selection]) => ({
            name: selection.selectionName,
            outcomeId: outcomeId,
            marketId: selection.marketId,
            marketName: selection.market,
            marketStatus: selection.changes.status,
          }),
        ),
      };

      state.betType = BetType.Single;
    },
    checkSelectionsAreStillValid(
      state,
      action: PayloadAction<Partial<Record<Permission, PermissionReason>>>,
    ) {
      const isIntRacingBetsBlocked =
        action?.payload?.submitInternationalRacingBet &&
        action?.payload?.submitInternationalRacingBet ===
          PermissionReason.BLOCKED;

      const isTop4BetBlocked =
        action?.payload?.submitTop4Bet &&
        action?.payload?.submitTop4Bet !== PermissionReason.GRANTED;

      const isPlaceBetBlocked =
        action?.payload?.submitPlaceBet &&
        action?.payload?.submitPlaceBet !== PermissionReason.GRANTED;

      const isSRMBetBlocked =
        action?.payload?.submitSRMBet &&
        action?.payload?.submitSRMBet !== PermissionReason.GRANTED;

      const { selections } = state;

      Object.keys(selections).forEach((outcomeId) => {
        const selection = selections[outcomeId];

        if (selection.sourceType === "race") {
          if (selection.market === "Top 4" && isTop4BetBlocked) {
            delete selections[outcomeId];
          }

          if (selection.market === "Top 3" && isTop4BetBlocked) {
            delete selections[outcomeId];
          }
          if (selection.market === "Top 2" && isTop4BetBlocked) {
            delete selections[outcomeId];
          }

          if (selection.market === "Place" && isPlaceBetBlocked) {
            delete selections[outcomeId];
          }

          if (selection?.subOutcomes?.length > 0 && isSRMBetBlocked) {
            delete selections[outcomeId];
          }

          if (
            selection?.attributes?.region === "ROW" &&
            isIntRacingBetsBlocked
          ) {
            delete selections[outcomeId];
          }
        }
      });
    },
    removeSubOutcome(
      state,
      action: PayloadAction<{ outcomeId: string; subOutcomeId: string }>,
    ) {
      state.selections[action.payload.outcomeId].subOutcomes = state.selections[
        action.payload.outcomeId
      ].subOutcomes.filter(
        (subOutcome) => subOutcome.outcomeId !== action.payload.subOutcomeId,
      );
    },
    setSubOutcomeStatus(
      state,
      action: PayloadAction<{
        outcomeId: string;
        subOutcomeId: string;
        status: MarketStatus;
      }>,
    ) {
      state.selections[action.payload.outcomeId].subOutcomes = state.selections[
        action.payload.outcomeId
      ].subOutcomes.map((subOutcome) =>
        subOutcome.outcomeId === action.payload.subOutcomeId
          ? { ...subOutcome, marketStatus: action.payload.status }
          : subOutcome,
      );
    },
    sharedEntryTracking(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<SharedEntryTracking>,
    ) {
      return state;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    viewedSharedEntry(state, _action: PayloadAction<any>) {
      return state;
    },
  },
});

// #endregion

export type BetslipEventType =
  | "RACER"
  | "MATCH"
  | "OUTRIGHT"
  | "TOURNAMENT"
  | "SEASON"
  | "RACE"
  | "RACE_MEETING";

// #region utility functions
export const findBetsSlipSourceType = (
  eventType: BetslipEventType,
  mainMarket = false,
): SourceType =>
  mainMarket
    ? "match"
    : eventType === "MATCH"
      ? "exotic"
      : eventType === "OUTRIGHT" ||
          eventType === "TOURNAMENT" ||
          eventType === "SEASON"
        ? "outright"
        : "race";

const findMarket = (
  marketId: string,
  event: Event,
): { market: EventMarket; sourceType: SourceType } => {
  if (event.mainMarket && event.mainMarket?.id === marketId) {
    return {
      market: event.mainMarket,
      sourceType: findBetsSlipSourceType(event.eventType, true),
    };
  }

  return {
    market: event.markets.find((market) => market.id === marketId),
    sourceType: findBetsSlipSourceType(event.eventType),
  };
};

const getBetslipSelection = (
  event: Event,
  pick: ToggleSelectionPayload,
  hub: betslipHubType,
): BetSelectionType => {
  const market = findMarket(pick.marketId, event)?.market;

  if (pick.subSelections) {
    if (
      pick.subSelections.every((outcome) =>
        isExoticMarketType(outcome.marketName),
      )
    ) {
      const selectedRunners: SubOutcome[][] = pick.subSelections.reduce(
        (acc, outcome) => {
          const { outcomeType, attributes, outcomeResult, outcomeId } = outcome;
          const position = exoticPositionFromOutcomeMap.get(outcomeType);
          const addRunnerToPosition = addToAccumulator(acc);

          const subOutcome = { outcomeId, attributes, outcomeResult };

          if (outcomeType === "RUNNER_TOP_2") {
            // this is a quinella edge case
            // top two means both first and second so to need to duplicate the same
            // numbers for first position
            addRunnerToPosition(1, subOutcome);
          }
          addRunnerToPosition(position, subOutcome);
          return acc;
        },
        [],
      );
      const marketName = pick.subSelections[0].marketName;
      const selections = Object.values(selectedRunners);

      return {
        exoticRunnerPositions: selections,
        exoticCombinations:
          marketName === "Quinella"
            ? calculateQuinellaCombinations(selections)
            : calculateExoticCombinations(selections),
        eventName: `Exotic - ${marketName === "First4" ? "First Four" : marketName}`,
        isMultiable: false,
        filters: event?.filters || [],
        sourceType: "exotic",
        market: `${event.eventName}`,
        selectionName: event.eventName,
        outcomeId: pick.outcomeId,
        eventId: pick.eventId,
        marketId: pick.marketId,
        odds: pick.odds ?? 0,
        stake: pick.stake || 0,
        isUsingPromo: false,
        startTime: event.scheduledStartTime,
        subOutcomes: pick.subSelections,
        changes: {
          newOdds: undefined,
          status: "ACTIVE",
        },
        hub,
        sport: event?.sport,
        competitors: event?.competitors,
        timezone: pick.timezone,
        semAvailableAtTs: event?.semAvailableAtTs,
        correlationId: uuidv4(),
        independentPerEvent: false,
      };
    }
    if (pick.type === "race") {
      return {
        filters: event?.filters || [],
        sourceType: pick.type,
        market: event.eventName,
        marketType: market?.marketType,
        tournamentId: event?.tournamentId,
        eventName: `Same Race Multi (${pick.subSelections.length} legs)`,
        selectionName: event.eventName,
        outcomeId: pick.outcomeId,
        eventId: pick.eventId,
        marketId: pick.marketId,
        odds: pick.odds ?? 0,
        stake: pick.stake || 0,
        isUsingPromo: false,
        startTime: event.scheduledStartTime,
        subOutcomes: pick.subSelections,
        isMultiable: true,
        changes: {
          newOdds: undefined,
          status: "ACTIVE",
        },
        hub,
        sport: event?.sport,
        competitors: event?.competitors,
        timezone: pick.timezone,
        semAvailableAtTs: event?.semAvailableAtTs,
        correlationId: uuidv4(),
        independentPerEvent: false,
        meetingId: pick.sourceId,
      };
    } else {
      return {
        filters: event?.filters || [],
        sourceType: pick.type,
        market: `${event.eventName}`,
        marketType: market?.marketType,
        tournamentId: event?.tournamentId,
        eventName: `Same Game Multi (${pick.subSelections.length} legs)`,
        selectionName: event.eventName,
        outcomeId: pick.outcomeId,
        eventId: pick.eventId,
        marketId: pick.marketId,
        odds: pick.odds ?? 0,
        stake: pick.stake || 0,
        isUsingPromo: false,
        startTime: event.scheduledStartTime,
        subOutcomes: pick.subSelections,
        isMultiable: true,
        changes: {
          newOdds: undefined,
          status: "ACTIVE",
        },
        hub,
        sport: event?.sport,
        competitors: event?.competitors,
        timezone: pick.timezone,
        semAvailableAtTs: event?.semAvailableAtTs,
        correlationId: uuidv4(),
        independentPerEvent: false,
      };
    }
  } else {
    const marketAndSourceType = findMarket(pick.marketId, event);
    const market = marketAndSourceType.market;
    const outcome = market.outcomes[pick.outcomeId];
    const competitor = event?.competitors?.[outcome?.competitorId];

    return {
      sourceType: marketAndSourceType.sourceType,
      market: market.name,
      marketType: market.marketType,
      tournamentId: event?.tournamentId,
      eventName: event.eventName,
      selectionName: outcome?.name,
      outcomeId: pick.outcomeId,
      eventId: pick.eventId,
      marketId: pick.marketId,
      odds: pick.odds ?? 0,
      stake: pick.stake || 0,
      isUsingPromo: false,
      startTime: market.nextBetStop ?? event.scheduledStartTime,
      isMultiable: market.attributes?.multiable ?? true,
      changes: {
        newOdds: undefined,
        status: "ACTIVE",
      },
      independentPerEvent: market.independentPerEvent ?? false,
      relatedEventIds: event.relatedEventIds,
      isSP: market.marketType.endsWith("_SP"),
      hub,
      outcome: {
        competitor,
        competitorId: outcome?.competitorId,
        eventId: null,
        id: pick.outcomeId,
        marketId: market.id,
        result: null,
        type: outcome.type,
        name: outcome.name,
      },
      isIntercepted: false,
      sport: event?.sport,
      competitors: event?.competitors,
      timezone: pick.timezone,
      attributes: pick?.attributes,
      semAvailableAtTs: event?.semAvailableAtTs,
      filters: event?.filters || [],
      correlationId: uuidv4(),
      meetingId: pick.sourceId,
    };
  }
};

// #endregion

// #region Thunks
export const toggleSelection =
  (
    event: Event,
    pick: ToggleSelectionPayload,
    hub: betslipHubType,
    force = false,
  ) =>
  async (
    dispatch: (payload: PayloadAction<any> | any) => any,
    getState: () => RootState,
  ) => {
    const { betslip } = getState();

    const selection = getBetslipSelection(event, pick, hub);

    const existingMultiOutcomeId = findMultiOutcomeId(
      betslip,
      selection?.subOutcomes?.map((s) => s.outcomeId),
    );

    if (betslip.selections[selection.outcomeId]) {
      dispatch(removeSelection(selection.outcomeId));
    } else if (existingMultiOutcomeId && !force) {
      return;
    } else if (existingMultiOutcomeId && force) {
      dispatch(removeSelection(existingMultiOutcomeId));
    } else {
      if (!(isDisplayMode("tablet") || isDisplayMode("mobile"))) {
        dispatch(setMenu("right"));
        dispatch(setPanel("betslip"));
      }
      dispatch(addSelection(selection));
      dispatch({
        type: BET_ADDED_TO_SLIP,
        payload: {
          name: event.eventName,
          sport: event.sport,
          ...pick,
        },
      });
    }

    if (betslip.betslipUIState === BetslipUIStates.submitting) {
      dispatch(setBetSlipState(BetslipUIStates.active));
    }

    if (isDisplayMode("desktop")) {
      dispatch(setPanel("betslip"));
    }
  };

export const setIsConfirming =
  () =>
  async (
    dispatch: (payload: PayloadAction<any>) => any,
    getState: () => RootState,
  ) => {
    const userFilters = getState().auth.attributes?.eventFilters || [];
    const usedSelections = Object.values(getState()?.betslip?.selections || {})
      .filter((s) => selectionCanBeCounted(s, userFilters))
      .map((s) => s.outcomeId);
    dispatch(updateBetslip({ usedSelections }));
    dispatch(removeAccepted());
    dispatch(removeAccepted());
    dispatch(setBetSlipState(BetslipUIStates.confirming));
  };

/**
 * Is this a potential duplicate betslip submission
 */
export const isDuplicate = (
  a: Partial<BetslipState>,
  b: Partial<BetslipState>,
) => {
  if (!a || !b) {
    return false;
  }
  if (a.betType != b.betType) {
    return false;
  }
  if (!a.selections || !b.selections) {
    return false;
  }
  if (!a.stakePerCombination || !b.stakePerCombination) {
    return false;
  }

  const isMulti = a.betType === "MULTI";
  const multiStakeMatch = isMulti ? a.multiStake === b.multiStake : true;

  const selectionsMatch = isMatchWith(a.selections, b.selections, (s1, s2) => {
    return s1.outcomeId === s2.outcomeId && s1.stake === s2.stake;
  });

  const combosMatch = isMatchWith(
    a.stakePerCombination,
    b.stakePerCombination,
    (c1, c2) => {
      return c1.total === c2.total;
    },
  );

  return selectionsMatch && combosMatch && multiStakeMatch;
};

const isMultiBetslip = (betslip: BetslipState) => {
  return betslip.betType === BetType.Multi;
};

const findMultiOutcomeId = (betslip: BetslipState, outcomeIds) =>
  Object.values(betslip.selections)?.find(
    (x) =>
      x?.subOutcomes?.every((y) => outcomeIds?.includes(y.outcomeId)) &&
      outcomeIds?.length === x.subOutcomes?.length,
  )?.outcomeId;

const getComboType = (correlationId: string, betslip: BetslipState) => {
  const combos = Object.values(betslip.stakePerCombination);

  const comboIndex = combos.findIndex(
    (combo) => combo.correlationId === correlationId,
  );

  return Object.keys(betslip.stakePerCombination)[comboIndex];
};

const isComboSelection = (correlationId: string, betslip: BetslipState) => {
  const selections = Object.values(betslip.selections);

  const selection = selections.find(
    (selection) => selection.correlationId === correlationId,
  );

  const comboType = getComboType(correlationId, betslip);

  return !selection && !!comboType;
};

const getOutcomeIdByCorrelation = (
  correlationId: string,
  betslip: BetslipState,
) => {
  const selections = Object.values(betslip.selections);

  const selection = selections.find(
    (selection) => selection.correlationId === correlationId,
  );

  return selection?.outcomeId;
};

const betAccepted = (
  msg: BetMessage,
  betslip: BetslipState,
  dispatch: (props?: any) => void,
) => {
  const {
    data: { outcomeIds, entryId, betId, correlationId },
  } = msg;

  const isMulti = isMultiBetslip(betslip);
  const isCombo = isComboSelection(correlationId, betslip);

  if (isCombo) {
    const comboType = getComboType(correlationId, betslip);
    dispatch(acceptCombination({ type: comboType, betId, entryId }));
  } else if (isMulti) {
    dispatch(
      updateBetslip({
        multiAccepted: entryId.toString(),
        betslipUIState: BetslipUIStates.active,
        multiAlternativeStakeReference: null,
        multiTokenAllocationId: null,
        betId,
      } as Partial<BetslipState>),
    );
  } else {
    if (outcomeIds.length === 1) {
      dispatch(
        updateSelection({
          outcomeId: outcomeIds[0],
          entryId,
          betId,
          alternativeStakeReference: null,
          allocationId: null,
        }),
      );
    } else {
      const multiOutcomeId = findMultiOutcomeId(betslip, outcomeIds);
      if (multiOutcomeId) {
        dispatch(
          updateSelection({
            outcomeId: multiOutcomeId,
            entryId,
            betId,
            alternativeStakeReference: null,
            allocationId: null,
          }),
        );
      } else {
        // if we are here it means we have more than 1 outcomeId in the
        // selection but are not an SEM so the above multiOutcomeId could not be
        // resolved. In this case retrieve the outcomeId using the bet
        // correlationId
        const outcomeId = getOutcomeIdByCorrelation(correlationId, betslip);

        if (outcomeId) {
          dispatch(
            updateSelection({
              outcomeId,
              entryId,
              betId,
              alternativeStakeReference: null,
            }),
          );
        }
      }
    }
  }
};

const handleMultiRejection = (
  dispatch: (props?: any) => void,
  errors: BetMessageError[],
  selections: Record<string, BetSelectionType>,
  isMultiOutcome: boolean,
  multiOutcomeId: string,
  alternativeStake?: number,
) => {
  if (!errors.length) {
    // dispatch a generic error, unlikely to actually happen
    dispatch(
      updateBetslip({
        multiRejected: {
          reason: "multi rejected without error data",
          message: "Something went wrong",
        },
      }),
    );
    return;
  }

  // any error that doesn't belong to selections via correlationId - we start with all errors, and remove if they are correlated
  const errorsForMulti = [...errors];

  // loop through the errors we have
  errors.forEach((error, index) => {
    // see if this error belongs to a selection with a matching correlationId
    if (!error.propertyPath) {
      return;
    }

    // figure out what selections the errors apply to
    Object.values(selections).forEach((selection) => {
      const relevantToSelection = error.propertyPath?.startsWith(
        `selections.${selection.correlationId}`,
      );
      if (!relevantToSelection) {
        return;
      }

      // the error relates to selection, so we can remove it from the copy we made of the errors
      errorsForMulti.splice(index, 1);

      const reason = error.messageTemplate.slice(1, -1);
      const message = error.message;
      const outcomeId =
        isMultiOutcome && multiOutcomeId ? multiOutcomeId : selection.outcomeId;

      // add each correlated error to the selection with the outcomeId
      dispatch(
        updateSelection({
          outcomeId,
          rejected: {
            reason,
            message,
            // don't worry about the alternative stake here, "multiRejected" below will deal with it
          },
        }),
      );
    });
  });

  // these are errors that apply to the multi - not correlated to individual selections
  if (errorsForMulti.length) {
    const firstError = errorsForMulti[0];
    dispatch(
      updateBetslip({
        multiRejected: {
          reason: firstError?.messageTemplate.slice(1, -1),
          message: firstError?.message,
          alternativeStake: alternativeStake,
        },
      }),
    );
  }
};

const betRejected = (
  msg: BetMessage,
  betslip: BetslipState,
  dispatch: (props?: any) => void,
) => {
  const {
    data: { outcomeIds, alternativeStake, betId, correlationId },
    errors,
  } = msg;

  const reason = errors[0]?.messageTemplate.slice(1, -1);
  const message = errors[0]?.message;
  const multiOutcomeId = findMultiOutcomeId(betslip, outcomeIds);

  const isMulti = isMultiBetslip(betslip);
  const isMultiOutcome = outcomeIds.length > 1;
  const isCombo = isComboSelection(correlationId, betslip);

  // NOTE: this needs testing
  if (isMulti && alternativeStake) {
    dispatch(
      updateBetslip({
        multiAlternativeStakeReference: betId,
      }),
    );
  }

  if (isCombo) {
    const comboType = getComboType(correlationId, betslip);
    dispatch(
      rejectCombination({ type: comboType, reason, message, alternativeStake }),
    );
  } else if (isMulti) {
    handleMultiRejection(
      dispatch,
      errors,
      betslip.selections,
      isMultiOutcome,
      multiOutcomeId,
      alternativeStake,
    );
  } else {
    // everything else
    dispatch(
      updateSelection({
        outcomeId: isMultiOutcome ? multiOutcomeId : outcomeIds[0],
        alternativeStakeReference: alternativeStake ? betId : null,
        rejected: {
          reason,
          message,
          alternativeStake,
        },
      }),
    );
  }
};

const betIntercepted = (
  msg: BetMessage,
  betslip: BetslipState,
  dispatch: (props?: any) => void,
) => {
  const {
    data: { outcomeIds },
  } = msg;

  const isMulti = isMultiBetslip(betslip);
  if (!isMulti) {
    if (outcomeIds.length === 1) {
      dispatch(
        updateSelection({ outcomeId: outcomeIds[0], isIntercepted: true }),
      );
    } else {
      const multiOutcomeId = findMultiOutcomeId(betslip, outcomeIds);
      if (multiOutcomeId) {
        dispatch(
          updateSelection({ outcomeId: multiOutcomeId, isIntercepted: true }),
        );
      }
    }
  }
};

const dispatchAnalyticsEvents = (
  dispatch: (props?: any) => void,
  betslip: BetslipState,
  sortedSelections: BetSelectionType[],
  currency: string,
  profile: Profile,
  messages: BetMessage[],
) => {
  const isMulti = isMultiBetslip(betslip);
  const combinedOdds = isMulti
    ? Object.values(sortedSelections).reduce(
        (prev, curr) => (prev === 0 ? curr.odds : prev * curr.odds),
        0,
      )
    : sortedSelections[0]?.odds || 0;

  const maxPayout = isMulti
    ? calculatePayout(
        betslip.multiStake,
        combinedOdds,
        betslip.isMultiUsingPromo,
      )
    : Object.values(sortedSelections).reduce(
        (prev, curr) =>
          prev + calculatePayout(curr.stake, curr.odds, curr.isUsingPromo),
        0,
      );
  const countByTitle = countBy(betslip.selections, "market");
  const commonTitle = Object.keys(countByTitle).reduce(
    (previous, current) =>
      countByTitle[previous] > countByTitle[current] ? previous : current,
    "none",
  );
  const startTime = sortedSelections[0]?.startTime || new Date();
  const hoursBeforeStart = Math.round(
    BettingTransforms.getHoursUntil(startTime),
  );

  const messageOutcomeNames = {
    "bet-accepted": "Accepted",
    "bet-rejected": "Rejected",
  };

  for (const msg of messages) {
    const outcome = messageOutcomeNames[msg?.code];

    if (outcome) {
      const {
        data: { outcomeIds, entryId },
      } = msg;

      const isCombo = isComboSelection(msg.data.correlationId, betslip);
      const comboType = getComboType(msg.data.correlationId, betslip);
      const comboStake = betslip.stakePerCombination[comboType]?.total;

      dispatch({
        type: BET_SUBMITTED,
        payload: {
          entryId,
          type: betslip.betType,
          title: isCombo
            ? `Any ${comboType}`
            : isMulti
              ? commonTitle
              : sortedSelections[0].market,
          legs: outcomeIds.length,
          stake: isCombo
            ? comboStake
            : isMulti
              ? betslip.multiStake
              : sortedSelections[0].stake,
          odds: isCombo ? 0 : isMulti ? combinedOdds : sortedSelections[0].odds,
          estPayout: isCombo
            ? 0
            : isMulti
              ? maxPayout / 100
              : calculatePayout(
                  sortedSelections[0].stake,
                  sortedSelections[0].odds,
                  sortedSelections[0].isUsingPromo,
                ) / 100,
          currency,
          market: isCombo ? `Any ${comboType}` : sortedSelections[0].market,
          startTime: isCombo
            ? new Date()
            : isMulti
              ? startTime
              : sortedSelections[0]?.startTime,
          hoursBeforeStart,
          outcome,
          isComboMulti: isCombo,
        },
        meta: {
          email: profile.email,
          name: `${profile.firstName} ${profile.lastName}`,
        },
      });
    }
  }
};

export const submitBet =
  (currency: CurrencyType, acceptOddsPreference: string, profile: Profile) =>
  async (
    dispatch: (props?: any) => void,
    getState: () => { auth: any; betslip: BetslipState },
  ) => {
    const {
      betslip,
      auth: { token, userId, attributes },
    } = getState();
    const userEventFilters = attributes?.eventFilters || [];
    if (betslip.betslipUIState === BetslipUIStates.submitting) {
      return;
    }

    const [lastSubmittedBetSlip] =
      localObject.getValue<Partial<BetslipState>>(KEY_LAST_BETSLIP);
    if (
      lastSubmittedBetSlip &&
      isDuplicate(betslip, lastSubmittedBetSlip) &&
      betslip.betslipUIState !== BetslipUIStates.acceptDuplicate
    ) {
      dispatch(setBetSlipState(BetslipUIStates.duplicate));
      dispatch(duplicateBetAttempted());
      return;
    }

    dispatch(updateBetslip({ betslipUIState: BetslipUIStates.submitting }));

    const isMulti = betslip.betType === BetType.Multi;

    const sortedSelections = Object.values({ ...betslip.selections })
      .filter((selection) => selectionCanBeCounted(selection, userEventFilters))
      .filter((s) => (isMulti ? true : s.stake > 0))
      .sort((a, b) => (a?.stake * 100 >= b?.stake * 100 ? 1 : -1));

    let payload: Record<string, any> = {};

    let acceptOddsChanges = "NONE";

    if (acceptOddsPreference === "AUTO") {
      acceptOddsChanges = "ANY";
    }
    if (acceptOddsPreference === "AUTO_HIGHER") {
      acceptOddsChanges = "HIGHER";
    }

    const channel = getBettingChannel();

    const combinations = betslip.stakePerCombination;

    const combinationBets = Object.keys(combinations)
      .map((type) => {
        const stake = betslip.stakePerCombination[type].total;
        const correlationId = betslip.stakePerCombination[type].correlationId;
        const isPromo = betslip.stakePerCombination[type].isPromo;

        // ignore empty stakes
        if (stake === 0) {
          return null;
        }

        return {
          stakeSource: isPromo ? "PROMOTION_USER" : "AVAILABLE",
          stake: new BigNumber(stake).multipliedBy(100).toNumber(),
          selectionsRequiredToWin: Number(type),
          correlationId,
          selections: Object.keys(betslip.selections).map(
            (outcomeId: string) => {
              const selection = betslip.selections[outcomeId];
              const subOutcomes = selection.subOutcomes;
              const outcomeIds = subOutcomes
                ? subOutcomes.map((subOutcome) => subOutcome.outcomeId)
                : [outcomeId];

              return {
                odds: selection.odds,
                outcomeIds,
                // NOTE: don't need correlationId in combo multis
              };
            },
          ),
        };
      })
      .filter(Boolean);

    if (isMulti) {
      payload = {
        currency: currency,
        acceptOddsChanges,
        bets: [
          betslip.multiStake > 0
            ? {
                stakeSource: betslip.isMultiUsingPromo
                  ? "PROMOTION_USER"
                  : "AVAILABLE",
                stake: new BigNumber(betslip.multiStake)
                  .multipliedBy(100)
                  .toNumber(),
                correlationId: uuidv4(),
                alternativeStakeReference:
                  betslip.multiAlternativeStakeReference,
                selections: sortedSelections.map(
                  ({ odds, outcomeId, subOutcomes, correlationId }) => {
                    const outcomeIds = subOutcomes
                      ? subOutcomes.map((subOutcome) => subOutcome.outcomeId)
                      : [outcomeId];

                    return {
                      odds,
                      outcomeIds,
                      correlationId,
                    };
                  },
                ),
                tokenIdAttemptedToClaim: betslip.multiTokenAllocationId,
              }
            : null,
          ...combinationBets,
        ].filter(Boolean),
        channel,
      };
    } else {
      payload = {
        currency: currency,
        acceptOddsChanges,
        bets: [
          ...sortedSelections.map(
            ({
              stake,
              odds,
              outcomeId,
              isUsingPromo,
              alternativeStakeReference,
              subOutcomes,
              correlationId,
              allocationId,
              exoticCombinations,
            }) => {
              const isExotic = !!exoticCombinations;

              const outcomeIds = subOutcomes
                ? subOutcomes.map((subOutcome) => subOutcome.outcomeId)
                : [outcomeId];

              return {
                stake: new BigNumber(stake).multipliedBy(100).toNumber(),
                correlationId,
                stakeSource: isUsingPromo ? "PROMOTION_USER" : "AVAILABLE",
                alternativeStakeReference,
                ...(isExotic && { exoticCombinations }),
                selections: [
                  {
                    ...(isExotic && { selectionType: "Exotic" }),
                    odds: isExotic ? "1.00" : odds,
                    outcomeIds,
                  },
                ],
                tokenIdAttemptedToClaim: allocationId,
              };
            },
          ),
          ...combinationBets,
        ],
        channel,
      };
    }

    const numResponses = payload.bets.length + 1;

    const message = new Message("betting-submit-slip", payload, {
      token,
      userId,
      numResponses: numResponses,
    });

    try {
      const { watch, id: messageId } = await message.sent;
      //We got a response from the api that the bet has been submitted.
      dispatch(
        updateBetslip({
          messageId,
        }),
      );
      //Store this betslip, so we can compare with future submissions for duplicate bets
      localObject.setValue(KEY_LAST_BETSLIP, {
        betType: betslip.betType,
        selections: betslip.selections,
        multiStake: betslip.multiStake,
        stakePerCombination: betslip.stakePerCombination,
        messageId,
      });
      //wait for all response messages to be received
      let messages = (await watch) as BetMessage[];

      const hasRejectedWithStake = messages.some(
        (message) =>
          message.code === "bet-rejected" && message.data?.alternativeStake,
      );

      // when we have a rejected bet artificially delay the response to allow firestore to get updated balance
      // it is very hacky, but the way backend publishes data is async, and we need to wait for it to be updated
      // 400ms is a magic number that works for now, but will fall apart for slower connections
      // this would only affect small subset of users, so we are not worried about it for now
      if (hasRejectedWithStake) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      }

      const processMessages = (messages: BetMessage[], finalRender = true) => {
        for (const msg of messages) {
          if (msg?.code === "bet-accepted") {
            betAccepted(msg, betslip, dispatch);
          } else if (msg.code === "bet-rejected") {
            // We revert the last submitted bet to the previous one when it fails. i.e. We only keep this latest bet when successful.
            finalRender &&
              localObject.setValue(KEY_LAST_BETSLIP, lastSubmittedBetSlip);
            betRejected(msg, betslip, dispatch);
          } else if (msg?.code === "bet-intercepted" && !finalRender) {
            betIntercepted(msg, betslip, dispatch);
          }
        }
      };

      const interceptedBets = messages.filter(
        (x) => x.code === "bet-intercepted",
      );

      if (interceptedBets.length > 0) {
        const maxTimeoutAt = interceptedBets.reduce(
          (currentMax: Date, currentMessage: any) =>
            parseISO(currentMessage?.data.timeoutAt) < currentMax
              ? currentMax
              : parseISO(currentMessage.data.timeoutAt),
          new Date(),
        );

        dispatch(setBetSlipState(BetslipUIStates.intercepted));

        processMessages(messages, false);

        messages = (await message.watch(
          messageId,
          numResponses + interceptedBets.length,
          differenceInSeconds(maxTimeoutAt, new Date()) + TIMEOUT_PADDING,
        )) as BetMessage[];
      }

      processMessages(messages);

      dispatch(clearIntercepted());

      dispatchAnalyticsEvents(
        dispatch,
        betslip,
        sortedSelections,
        currency,
        profile,
        messages,
      );
    } catch (error) {
      console.error(error);
      const isTimedOut = error?.message?.includes(
        "Timed out waiting for response",
      );
      if (isTimedOut) {
        dispatch(
          setBetslipMessage(
            "Bet request timed out. Confirm bet placement in your Pending tab",
          ),
        );
        dispatch(clearIntercepted());
      } else {
        toast({
          title: "Error placing bet",
          variant: "danger",
          description: parseError(error),
        });
      }
    } finally {
      dispatch(
        updateBetslip({
          betslipUIState: BetslipUIStates.active,
          messageId: null,
        }),
      );
    }
  };

// #endregion

// #region Selections

export const selectUserAttributes = (state: { auth: AuthState }) =>
  state.auth?.attributes;

export const selectUserFilters = createSelector(
  selectUserAttributes,
  (attributes) => attributes?.eventFilters ?? [],
);

const selectionCanBeCounted = (
  selection: BetSelectionType,
  userEventFilters: string[] = [],
) => {
  const hasRejected = !!selection?.rejected;

  const hasOddsChange = !!selection?.changes?.newOdds;

  const activeStatuses = ["ACTIVE", "LIVE", "OPEN"];

  const fitsFilter =
    userEventFilters.length > 0
      ? userEventFilters.every((filter) => selection.filters.includes(filter))
      : true;

  return !(
    hasRejected ||
    !activeStatuses.includes(selection?.changes?.status) || // exclude if status is not active
    hasOddsChange ||
    !fitsFilter
  );
};

// Single Location for all payout logic
export const calculatePayout = (
  stake: number,
  odds: number,
  isUsingPromo: boolean,
): number => {
  const bStake = new BigNumber(stake).multipliedBy(100);
  const bOdds = new BigNumber(odds);

  const payoutAdjustment = isUsingPromo ? bStake : new BigNumber(0);
  const result = bOdds.multipliedBy(bStake).minus(payoutAdjustment);

  return result.toNumber();
};

export const selectBetslip = (state: { betslip: BetslipState }) =>
  state.betslip;

export const selectBetslipIsReviewingMessages = createSelector(
  selectBetslip,
  (betslip) => {
    let isReviewingMessages = false;
    if (betslip.betslipUIState === BetslipUIStates.intercepted) return false;

    Object.values(betslip.selections).forEach((selection) => {
      if (selection.betId) {
        isReviewingMessages = true;
      }
    });

    Object.values(betslip.stakePerCombination).forEach((combination) => {
      if (combination.betId) {
        isReviewingMessages = true;
      }
    });

    if (betslip.betType === BetType.Multi && betslip.multiAccepted) {
      isReviewingMessages = true;
    }

    return isReviewingMessages;
  },
);

export const selectUnusedSelections = createSelector(
  [selectBetslip, selectBetslipIsReviewingMessages],
  (betslip, isReviewing): string[] => {
    if (isReviewing) {
      return Object.keys(betslip.selections).reduce((selections, outcomeId) => {
        const selection = betslip.selections[outcomeId];

        if (selection.betId || selection.rejected) {
          return selections;
        }

        return [...selections, outcomeId];
      }, []);
    }

    return [];
  },
);

export const selectIsDuplicateSubOutcomeSelection = createSelector(
  [selectBetslip],
  (betslipState: BetslipState) => (outcomeId: string) => {
    // First, find the selection with the provided outcomeId.
    const targetSelection = Object.values(betslipState.selections).find(
      (selection) => selection.outcomeId === outcomeId,
    );

    // If no such selection exists, or if it doesn't have any subOutcomes, return false.
    if (!targetSelection || !targetSelection.subOutcomes) {
      return false;
    }

    // Get the sorted subOutcomeIds of the target selection.
    const targetSubOutcomeIds = targetSelection.subOutcomes
      .map((so) => so.outcomeId)
      .sort();

    // Then, check if there is any other selection with the same subOutcomeIds.
    return Object.values(betslipState.selections).some((selection) => {
      if (selection === targetSelection || !selection.subOutcomes) {
        return false;
      }

      const subOutcomeIds = selection.subOutcomes
        .map((so) => so.outcomeId)
        .sort();

      return (
        JSON.stringify(subOutcomeIds) === JSON.stringify(targetSubOutcomeIds)
      );
    });
  },
);

export const selectFullSelections = createSelector(
  selectBetslip,
  selectIsDuplicateSubOutcomeSelection,
  (betslip, isDuplicateSubOutcomeSelection) => {
    if (
      betslip.betType === BetType.Single &&
      [BetslipUIStates.confirming, BetslipUIStates.submitting].includes(
        betslip.betslipUIState,
      )
    ) {
      return Object.values(betslip.selections).reduce(
        (acc, selection) => {
          if (
            selection.stake > 0 &&
            (selection.changes.status === "ACTIVE" ||
              selection.changes.status === "LIVE" ||
              selection.changes.status === "OPEN") &&
            !isDuplicateSubOutcomeSelection(selection.outcomeId)
          ) {
            return { ...acc, [selection["outcomeId"]]: selection };
          }

          return acc;
        },
        {} as Record<string, BetSelectionType>,
      );
    }

    if (
      betslip.betType === BetType.Multi &&
      [BetslipUIStates.confirming, BetslipUIStates.submitting].includes(
        betslip.betslipUIState,
      )
    ) {
      return Object.values(betslip.selections).reduce(
        (acc, selection) => {
          if (selection.changes?.newOdds !== undefined) {
            return acc;
          }
          if (
            selection.changes.status === "ACTIVE" ||
            selection.changes.status === "LIVE" ||
            selection.changes.status === "OPEN"
          ) {
            return { ...acc, [selection["outcomeId"]]: selection };
          }

          return acc;
        },
        {} as Record<string, BetSelectionType>,
      );
    }

    return betslip.selections;
  },
);

export const selectSelections = createSelector(
  selectFullSelections,
  (selections) => {
    return Object.keys(selections);
  },
);

export const selectUnfilteredSelections = createSelector(
  selectBetslip,
  (betslip) => Object.keys(betslip.selections),
);

export const selectSelectionsWithoutUnused = createSelector(
  [selectSelections, selectUnusedSelections],
  (selections, unusedSelections) => {
    return selections.filter(
      (outcomeId) => !unusedSelections.includes(outcomeId),
    );
  },
);

export const selectCanGetOddsUpdates = createSelector(
  selectBetslip,
  selectBetslipIsReviewingMessages,
  (betslip, isReviewingMessages) => {
    if (betslip.betslipUIState === BetslipUIStates.confirming) return false;
    if (betslip.betslipUIState === BetslipUIStates.intercepted) return false;
    if (betslip.betslipUIState === BetslipUIStates.submitting) return false;
    if (betslip.betslipUIState === BetslipUIStates.duplicate) return false;
    if (betslip.betslipUIState === BetslipUIStates.quickDepositing)
      return false;
    if (isReviewingMessages) return false;

    return true;
  },
);

export const selectUnfilteredSelectionsCount = createSelector(
  selectUnfilteredSelections,
  (unfilteredSelections) => unfilteredSelections.length,
);

export const selectBetType = createSelector(
  selectBetslip,
  (betslip) => betslip.betType,
);

export const selectIsMulti = createSelector(
  selectBetType,
  (betType) => betType === BetType.Multi,
);

export const selectMultiAccepted = createSelector(selectBetslip, (betslip) => {
  return betslip.multiAccepted;
});

export const selectMultiRejected = createSelector(selectBetslip, (betslip) => {
  return betslip.multiRejected;
});

export const selectComboTotalStake = createSelector(
  selectBetslip,
  ({ stakePerCombination }) =>
    parseStake(
      Object.values(stakePerCombination).reduce(
        (prev, curr) => prev + curr.total,
        0,
      ),
    ),
);

export const selectComboTotalStakeWithoutPromotion = createSelector(
  selectBetslip,
  ({ stakePerCombination }) =>
    parseStake(
      Object.values(stakePerCombination)
        .filter((combo) => !combo.isPromo)
        .reduce((prev, curr) => prev + curr.total, 0),
    ),
);

export const selectCombinedSinglesStake = createSelector(
  [selectFullSelections, selectUserFilters, selectComboTotalStake],
  (selections, userFilters, comboTotalStake) => {
    return Object.values(selections).reduce((prev, curr) => {
      return selectionCanBeCounted(curr, userFilters)
        ? prev + parseStake(curr.stake)
        : prev;
    }, comboTotalStake);
  },
);

export const selectCombinedSinglesStakeWithoutPromotions = createSelector(
  [
    selectFullSelections,
    selectUserFilters,
    selectComboTotalStakeWithoutPromotion,
  ],
  (selections, userFilters, comboTotalStake) => {
    return parseInt(
      Object.values(selections)
        .reduce((prev, curr) => {
          return selectionCanBeCounted(curr, userFilters) && !curr.isUsingPromo
            ? prev + parseStake(curr.stake)
            : prev;
        }, comboTotalStake)
        .toString(),
    );
  },
);

export const selectMultiStake = createSelector(
  selectBetslip,
  ({ multiStake }) => parseStake(multiStake),
);

export const selectCombinedMultiStake = createSelector(
  [selectMultiStake, selectComboTotalStake],
  (multiStake, comboTotalStake) => multiStake + comboTotalStake,
);

export const selectIsMultiUsingPromo = createSelector(
  selectBetslip,
  ({ isMultiUsingPromo }) => isMultiUsingPromo,
);

export const selectMultiTokenId = createSelector(
  selectBetslip,
  ({ multiTokenAllocationId }) => multiTokenAllocationId,
);

export const selectCombinedMultiStakeWithoutPromo = createSelector(
  [
    selectMultiStake,
    selectComboTotalStakeWithoutPromotion,
    selectIsMultiUsingPromo,
  ],
  (multiStake, comboTotalStake, isPromo) =>
    (isPromo ? 0 : multiStake) + comboTotalStake,
);

export const selectMustOfferDeposit = createSelector(
  [
    selectIsMulti,
    selectCombinedSinglesStakeWithoutPromotions,
    selectCombinedMultiStakeWithoutPromo,
  ],
  (isMulti, singleStake, multiStake) =>
    memoize((accountBalance: number) => {
      if (accountBalance == undefined) return false;

      const stake = isMulti ? multiStake : singleStake;
      return accountBalance < stake;
    }),
);

export const selectIsMultiOutcomeInBetslip = createSelector(
  [selectBetslip],
  (betslip) =>
    memoize((outcomeIds: string[]) => {
      return !!findMultiOutcomeId(betslip, outcomeIds);
    }),
);

export const selectSelectionsCount = createSelector(
  [
    selectFullSelections,
    selectIsMulti,
    selectIsDuplicateSubOutcomeSelection,
    selectUserFilters,
  ],
  (selections, isMulti, isDuplicateSubOutcomeSelection, userFilters) =>
    Object.values(selections).filter((selection) => {
      return (
        selectionCanBeCounted(selection, userFilters) &&
        (isMulti || selection.stake > 0) &&
        !isDuplicateSubOutcomeSelection(selection.outcomeId)
      );
    }).length,
);

export const selectMultiContainsAnOutright = createSelector(
  [selectFullSelections, selectIsMulti],
  (selections, isMulti) =>
    isMulti &&
    Object.values(selections).some(
      (selection) => selection.sourceType === "outright",
    ),
);

export const selectAcceptedOrRejectedCount = createSelector(
  selectFullSelections,
  (selections) =>
    Object.values(selections || {}).filter((selection) => {
      return selection?.rejected || selection?.betId;
    })?.length,
);

export const selectStakeAll = createSelector(
  selectBetslip,
  (betslip) => betslip.stakeAll,
);

export const selectBetslipUIStatus = createSelector(
  selectBetslip,
  ({ betslipUIState }) => betslipUIState,
);

export const selectBetslipIsConfirming = createSelector(
  selectBetslipUIStatus,
  (uiState) => uiState === BetslipUIStates.confirming,
);

export const selectBetslipHasAcceptedIsDuplicate = createSelector(
  selectBetslipUIStatus,
  (uiState) => uiState === BetslipUIStates.acceptDuplicate,
);

export const selectBetslipIsDuplicate = createSelector(
  selectBetslipUIStatus,
  (uiState) => uiState === BetslipUIStates.duplicate,
);

export const selectBetslipIsQuickDepositing = createSelector(
  selectBetslipUIStatus,
  (uiState) => uiState === BetslipUIStates.quickDepositing,
);

export const selectBetslipIsActive = createSelector(
  selectBetslipUIStatus,
  (uiState) => uiState === BetslipUIStates.active,
);

export const selectBetslipIsSubmitting = createSelector(
  selectBetslipUIStatus,
  (uiState) =>
    uiState === BetslipUIStates.submitting ||
    uiState === BetslipUIStates.intercepted,
);

export const selectBetslipIsIntercepted = createSelector(
  selectBetslipUIStatus,
  (uiState) => uiState === BetslipUIStates.intercepted,
);

export const selectCombinedOdds = createSelector(
  [selectBetslip, selectUserFilters],
  ({ selections }, userFilters) => {
    return Object.values(selections).reduce((prev, curr) => {
      // Note: 0 is used to display a "-" in the UI and relied on for calculations
      if (!selectionCanBeCounted(curr, userFilters)) return prev;
      if (prev === 0) return curr.odds;
      return prev * curr.odds;
    }, 0);
  },
);

export const selectCombinedOddsIncludingNewOdds = createSelector(
  [selectBetslip],
  ({ selections }) => {
    // We want to add up and display all odds, defaulting to new odds if available.
    const hasSP = Object.values(selections).some((selection) => selection.isSP);
    if (hasSP) {
      // the UI will show '-' when this is 0
      return 0;
    }

    return Object.values(selections).reduce((prev, curr) => {
      const odds = getCurrentOdds(curr);
      // start at 0 as per selectCombinedOdds above
      return !prev ? odds : prev * odds;
    }, 0);
  },
);

export const selectHasSPMarkets = createSelector(
  selectBetslip,
  ({ selections }) =>
    Object.values(selections ?? {})?.some((selection) => selection.isSP),
);

export const selectHasExoticMarkets = createSelector(
  selectBetslip,
  ({ selections }) =>
    Object.values(selections ?? {})?.some(
      (selection) => selection.exoticRunnerPositions,
    ),
);

export const selectPayoutForSelection = createSelector(
  selectFullSelections,
  (selections) => {
    return memoize((outcomeId: string) =>
      calculatePayout(
        selections[outcomeId].stake,
        selections[outcomeId].changes?.newOdds ?? selections[outcomeId].odds,
        selections[outcomeId].isUsingPromo,
      ),
    );
  },
);

export const selectOddsFormat = createSelector(
  selectBetslip,
  ({ oddsFormat }) => oddsFormat as OddsFormat,
);

export const selectCombinedSinglesPayout = createSelector(
  [selectBetslip, selectUserFilters],
  ({ selections }, eventFilters) => {
    return Object.values(selections).reduce(
      (prev, curr) =>
        selectionCanBeCounted(curr, eventFilters)
          ? prev + calculatePayout(curr.stake, curr.odds, curr.isUsingPromo)
          : prev,
      0,
    );
  },
);

export const selectMultiPayout = createSelector(
  [selectCombinedOdds, selectBetslip],
  (combinedOdds, betslip) =>
    calculatePayout(
      betslip.multiStake,
      combinedOdds,
      betslip.isMultiUsingPromo,
    ),
);

export const selectIsPromoAvailableForMulti = createSelector(
  selectBetslip,
  ({ multiStake }) =>
    memoize((promoBalance: number) => parseStake(multiStake) <= promoBalance),
);

export const selectIsPromoAvailableForSelection = createSelector(
  [selectBetslip, selectUserFilters],
  ({ selections }, userFilters) =>
    (promoBalance: number, selection: BetSelectionType) => {
      const totalPromoUsed = Object.values(selections).reduce((prev, curr) => {
        if (selectionCanBeCounted(curr, userFilters)) {
          if (selection.outcomeId === curr.outcomeId) return prev;
          if (!curr.isUsingPromo) return prev;
          return prev + parseStake(curr.stake);
        }
        return prev;
      }, 0);

      const normalizedStake = parseStake(selection.stake);
      return promoBalance - totalPromoUsed >= normalizedStake;
    },
);

export const selectUsedComboPromoAmount = createSelector(
  selectBetslip,
  (betslip) => {
    // calculate the total amount of promo used in the betslip
    return Object.keys(betslip.stakePerCombination).reduce((total, type) => {
      if (betslip.stakePerCombination[type].isPromo) {
        return total + parseStake(betslip.stakePerCombination[type].total);
      }

      return total;
    }, 0);
  },
);

export const selectUsedPromoAmount = createSelector(
  selectBetslip,
  (betslip) => {
    // calculate the total amount of promo used in the betslip
    return Object.keys(betslip.selections).reduce(
      (usedPromoBalance, outcomeId) => {
        if (betslip.selections[outcomeId].isUsingPromo) {
          return (
            usedPromoBalance + parseStake(betslip.selections[outcomeId].stake)
          );
        }

        return usedPromoBalance;
      },
      0,
    );
  },
);

export const selectAcceptedCount = createSelector(selectBetslip, (betslip) => {
  return Object.values(betslip.selections).reduce(
    (prev, curr) => (curr.betId ? prev + 1 : prev),
    0,
  );
});

export const selectHasRelated = createSelector(
  selectFullSelections,
  (selections) => (outcomeId: string) => {
    const currentSelection = selections[outcomeId];
    if (!currentSelection) return false;
    return Object.values(selections).some((selection) => {
      if (selection.outcomeId === outcomeId) return false;

      if (selection.independentPerEvent) return false;

      if (!selection.isMultiable) {
        return true;
      }

      if (
        selection?.outcome?.competitorId &&
        currentSelection?.outcome?.competitorId &&
        selection?.outcome?.competitorId ===
          currentSelection?.outcome?.competitorId
      )
        return true;

      // PKB-2822 - show related contingency message for FIFA markets where the competitor is the same as it will always be rejected
      if (currentSelection?.sport === "FIFA" && selection?.sport === "FIFA") {
        const currentSelectionCompetitorNames = Object.values(
          currentSelection?.competitors ?? {},
        ).map((competitor) => competitor.name);
        const selectionCompetitorNames = Object.values(
          selection?.competitors ?? {},
        ).map((competitor) => competitor.name);

        const selectionHasDuplicateCompetitors =
          currentSelectionCompetitorNames.some((name) =>
            selectionCompetitorNames.includes(name),
          );

        if (selectionHasDuplicateCompetitors) return true;
      }

      if (has(currentSelection, "exoticCombinations")) {
        return true;
      }

      if (
        !process.env.GATSBY_ENABLE_CROSS_PRODUCT_MULTI &&
        ((selection?.hub === "racing" && currentSelection?.hub !== "racing") ||
          (selection?.hub !== "racing" && currentSelection?.hub === "racing"))
      )
        return true;

      if (currentSelection?.relatedEventIds?.[selection.eventId]) {
        return true;
      }

      if (selection.relatedEventIds?.[currentSelection.eventId]) {
        return true;
      }

      return selection?.eventId === currentSelection?.eventId;
    });
  },
);

export const selectRejectedCount = createSelector(selectBetslip, (betslip) => {
  return Object.values(betslip.selections).reduce(
    (prev, curr) => (curr.rejected ? prev + 1 : prev),
    0,
  );
});

export const selectPermissions = (state) => state.auth.permissions;

export const selectCanViewLiveMarkets = createSelector(
  [selectPermissions],
  (permissions) => permissions?.["viewLiveMarkets"] === "GRANTED",
);

export const selectCanSubmitBet = createSelector(
  [
    selectBetslip,
    selectSelectionsCount,
    selectPermissions,
    selectIsDuplicateSubOutcomeSelection,
    selectUserFilters,
  ],
  (
    betslip,
    selectionsCount,
    permissions,
    isDuplicateSubOutcomeSelection,
    eventFilters,
  ) => {
    if (
      !Object.values(betslip.selections).every((selection) => {
        if (
          selection.hub === "sports" &&
          permissions?.["submitSportsBet"] !== "GRANTED"
        ) {
          return false;
        }
        if (
          selection.hub === "esports" &&
          permissions?.["submitEsportsBet"] !== "GRANTED"
        ) {
          return false;
        }
        if (
          selection.hub === "racing" &&
          permissions?.["submitRacingBet"] !== "GRANTED"
        ) {
          return false;
        }
        if (permissions?.["submitBet"] !== "GRANTED") {
          return false;
        }

        return true;
      })
    )
      return false;

    const isMulti = isMultiBetslip(betslip);
    const hasValidCombinations = Object.values(
      betslip.stakePerCombination,
    ).some((combo) => combo.total > 0);

    if (selectionsCount == 0 && !hasValidCombinations) {
      return false;
    }
    if (isMulti && selectionsCount === 1 && !hasValidCombinations) {
      return false;
    }
    if (
      isMulti &&
      (!betslip.multiStake || betslip.multiStake.toString() === "0") &&
      !hasValidCombinations
    ) {
      return false;
    }

    const canSubmitBet = betslip.betslipUIState === BetslipUIStates.active;

    if (
      Object.values(betslip.selections).some((selection) =>
        isDuplicateSubOutcomeSelection(selection.outcomeId),
      )
    ) {
      return false;
    }

    if (isMulti) {
      // we don't want to let people proceed if entire multi was rejected
      const multiRejected = !!betslip.multiRejected;

      // and we need to check each selection can be counted as well
      const hasValidMultiSelections = Object.values(betslip.selections).every(
        (selection) => selectionCanBeCounted(selection, eventFilters),
      );

      return canSubmitBet && !multiRejected && hasValidMultiSelections;
    }

    const validSingleSelectionsCount = Object.values(betslip.selections).reduce(
      (count, selection) =>
        selectionCanBeCounted(selection, eventFilters) && selection.stake > 0
          ? count + 1
          : count,
      0,
    );

    return (
      canSubmitBet && (validSingleSelectionsCount > 0 || hasValidCombinations)
    );
  },
);

export const selectRaceUrl = createSelector(
  [selectFullSelections],
  (selections) => {
    const selection = Object.values(selections || {})?.[0];
    if (!selection?.attributes) return;
    const { raceMeetingId } = selection?.attributes;
    return `/racing/betting/race/${raceMeetingId}/${selection?.eventId}/same-race-multi/`;
  },
);

export const selectHasMultiOutcomeSelection = createSelector(
  selectFullSelections,
  (selections) =>
    Object.values(selections).some(
      (selection) => selection.subOutcomes?.length > 1,
    ),
);

export const selectSemTypeIfSingleBetAndOnMultiTab = createSelector(
  selectFullSelections,
  (selections) => {
    const selectionsArray = Object.values(selections || {});
    if (
      selectionsArray.length === 1 &&
      selectionsArray.some((selection) => !!selection.subOutcomes)
    ) {
      return selectionsArray[0].sourceType;
    }

    return "";
  },
);

export const selectMulti = createSelector(
  [
    selectBetslip,
    selectFullSelections,
    selectBetslipIsConfirming,
    selectMultiPayout,
  ],
  (betslip, selections, isConfirming, multiPayout) => {
    return {
      selections,
      multiStake: betslip.multiStake,
      isMultiUsingPromo: betslip.isMultiUsingPromo,
      isConfirming,
      multiPayout,
    };
  },
);

export const selectBetslipMessage = createSelector(
  [selectBetslip],
  (betslip) => betslip.betslipMessage,
);

export const selectIsAlmostSRM = createSelector([selectBetslip], (betslip) => {
  // Cannot create an SRM bet if the betslip is not a multi
  if (betslip.betType !== BetType.Multi) return false;

  const selections = Object.values(betslip.selections);

  // if (selections.some((x) => x?.sport && x?.sport === "HARNESS_RACING"))
  //   return false;

  // Cannot create an SRM bet if there are any sports or esports selections
  if (
    selections.some((selection) =>
      ["sports", "esports"].includes(selection.hub),
    )
  )
    return false;

  // Cannot create an SRM bet if there are multiple outcomes for a selection
  if (selections.some((selection) => selection.subOutcomes?.length > 1))
    return false;

  // Cannot create an SRM bet if their markets are all not Win, Top 2, Top 3 or Top 4
  if (
    !selections.every(
      (selection) =>
        ["Win", "Top 2", "Top 3", "Top 4"].includes(selection.market) &&
        !selection.isSP &&
        selection.timezone,
    )
  ) {
    return false;
  }

  // check if there are duplicate eventIds
  if (
    [...new Set(selections?.map((selection) => selection?.eventId))]?.length !==
    1
  )
    return false;

  return true;
});

export const selectCanBeSRM = createSelector(
  [selectBetslip, selectIsAlmostSRM],
  (betslip, canBePartialSRM) => {
    if (!canBePartialSRM) return false;

    const selections = Object.values(betslip.selections);

    // Cannot create an SRM bet if there are less than 2 or more than 4 selections
    if (selections?.length < 2 || selections.length > 4) return false;

    // All selections must be active
    if (
      selections?.some((selection) => selection?.changes?.status !== "ACTIVE")
    )
      return false;

    // Cannot create an SRM bet if there are multiple selections for the same event
    if (
      !selections.every((selection) =>
        selections
          .filter((x) => x.outcomeId !== selection.outcomeId)
          .every((otherSelection) => {
            if (otherSelection.eventId === selection.eventId) {
              return otherSelection.selectionName !== selection.selectionName;
            }
            return false;
          }),
      )
    )
      return false;

    if (
      !getIsSRMAvailable({
        scheduledStartTime: selections?.[0]?.startTime,
        raceTimezone: selections?.[0]?.timezone,
        sport: selections?.[0]?.sport as any,
        semAvailableAtTs: selections?.[0]?.semAvailableAtTs,
      })
    )
      return false;

    const marketTypesCount = selections.reduce(
      (acc, selection) => {
        if (selection.market in acc) {
          acc[selection.market] += 1;
        } else {
          acc[selection.market] = 1;
        }
        return acc;
      },
      { Win: 0, "Top 2": 0, "Top 3": 0, "Top 4": 0 },
    ) as unknown as Record<string, number>;

    // Cannot create an SRM bet if there are more than 1 Win, 2 Top 2, 3 Top 3 or 4 Top 4 markets

    if (marketTypesCount["Win"] > 1) return false;
    if (marketTypesCount["Top 2"] + marketTypesCount["Win"] > 2) return false;
    if (
      marketTypesCount["Top 3"] +
        marketTypesCount["Top 2"] +
        marketTypesCount["Win"] >
      3
    )
      return false;
    if (
      marketTypesCount["Top 4"] +
        marketTypesCount["Top 3"] +
        marketTypesCount["Top 2"] +
        marketTypesCount["Win"] >
      4
    )
      return false;

    return true;
  },
);

export const selectBetId = createSelector(
  [selectBetslip],
  (betslip) => betslip.betId,
);

export const selectUsedAllocationIds = createSelector(
  [selectBetslip],
  (betslip) => {
    const isMulti = betslip.betType === BetType.Multi;

    if (isMulti) {
      return [betslip.multiTokenAllocationId].filter(Boolean);
    }

    return Object.values(betslip.selections)
      .reduce((acc, selection) => {
        if (selection.allocationId) {
          return [...acc, selection.allocationId];
        }
        return acc;
      }, [])
      .filter(Boolean);
  },
);

export const selectPossibleCombinations = createSelector(
  [selectBetslip, selectHasRelated],
  (betslip, hasRelated): { type: number; count: number; odds: number[] }[] => {
    const selections = Object.keys(betslip.selections);
    const hasRelatedSelections = selections.some((outcomeId) =>
      hasRelated(outcomeId),
    );
    const selectionsCount = selections.length;
    const isMulti = betslip.betType === "MULTI";

    // if there are related selections do not produce combinations
    if (hasRelatedSelections) {
      return [];
    }

    const combinations = [];

    const odds = Object.values(betslip.selections).map(getCurrentOdds);

    for (let i = 2; i <= selectionsCount; i++) {
      const combinationsCount = calculateCombinations(selectionsCount, i);

      if (combinationsCount < 1 || combinationsCount > 500) {
        continue;
      }

      if (isMulti && i === selectionsCount && combinationsCount === 1) {
        // on multi don't allow ALL selections
        continue;
      }

      combinations.push({
        type: i,
        count: combinationsCount,
        odds,
      });
    }

    return combinations;
  },
);

export const selectStakePerCombination = createSelector(
  [selectBetslip],
  (betslip) => {
    return betslip.stakePerCombination;
  },
);

/**
 * Calculate the combo payouts with the stake, Object keyed the same as stakePerCombo
 */
export const selectComboMultiPayouts = createSelector(
  [selectPossibleCombinations, selectStakePerCombination, selectHasSPMarkets],
  (combos, stakePerCombo, hasSP) => {
    const payouts: { [key: PropertyKey]: "TBD" | number } = {};
    combos.forEach(({ type, odds }) => {
      payouts[type] = hasSP
        ? "TBD"
        : calculatePotentialPayout(
            odds,
            stakePerCombo?.[type]?.total || 0,
            type,
          );
    });
    return payouts;
  },
);

/**
 * Combine all staked selections, including exotics and combos, to produce either: TBD, SP or the value (in cents)
 */
export const selectPayoutTotal = createSelector(
  [
    selectCombinedSinglesPayout,
    selectMultiPayout,
    selectComboMultiPayouts,
    selectIsMulti,
    selectHasExoticMarkets,
    selectHasSPMarkets,
    selectStakePerCombination,
    selectBetslip,
  ],
  (
    singlesPayout,
    multiPayout,
    combosPayouts,
    isMulti,
    hasExoticMarkets,
    hasSPMarkets,
    stakePerCombo,
    betslip,
  ): number | "TBD" => {
    const { selections, multiStake } = betslip;

    // filter out the combos that don't have a stake
    const comboPayoutValues = Object.keys(combosPayouts || {}).flatMap(
      (comboPayoutKey) =>
        stakePerCombo?.[comboPayoutKey]?.combo
          ? [combosPayouts[comboPayoutKey]]
          : [],
    );

    // Check if there's a stake on an exotic
    const hasExotic =
      hasExoticMarkets &&
      Object.values(selections).some(
        ({ stake, exoticRunnerPositions }) =>
          stake > 0 && exoticRunnerPositions?.length,
      );

    // if we have sp on anything in multi and a stake, we can show payout as "TBD"
    const multiHasSP =
      (multiStake > 0 || comboPayoutValues.length) && hasSPMarkets;

    // or if we have sp on a selection with a stake, or a staked combo and any "sp"
    const stakedSingleSelectionsHasSP = Object.values(selections).some(
      ({ stake, isSP }) => stake > 0 && isSP,
    );
    const stakedComboHasSp = comboPayoutValues.length && hasSPMarkets;
    const singleHasSP = stakedSingleSelectionsHasSP || stakedComboHasSp;

    // If Exotic, or has SP anywhere, the payout isn't the starting price its TBD
    if ((isMulti && multiHasSP) || (!isMulti && singleHasSP) || hasExotic) {
      return "TBD";
    }

    // get the total from multi or singles
    let total = isMulti ? multiPayout : singlesPayout;
    if (comboPayoutValues.length > 0) {
      // add the combo payouts
      total = BigNumber.sum(
        total,
        // filter to numbers and x100 to convert to cents like everything else
        ...comboPayoutValues.flatMap((el) =>
          typeof el === "number" ? [el * 100] : [],
        ),
      ).toNumber();
    }

    return total ? rounded(total) : 0;
  },
);

// #endregion

export const {
  clearIntercepted,
  setBetType,
  setStakeSingle,
  setStakeMulti,
  setMultiIsUsingPromo,
  clearRejected,
  removeRejected,
  clearAccepted,
  removeAccepted,
  addSelection,
  removeSelection,
  clearSelections,
  changeOdds,
  updateSelection,
  setIsUsingPromo,
  setPendingOddsChange,
  statusChangeOnSelection,
  updateBetslip,
  setBetSlipState,
  setStakeAllSingles,
  reuseSelection,
  setBetslipMessage,
  clearBetslipMessage,
  sharedEntryTracking,
  viewedSharedEntry,
  convertSelectionsToSRM,
  checkSelectionsAreStillValid,
  removeSubOutcome,
  setSubOutcomeStatus,
  setTokenAllocationId,
  setMultiTokenAllocationId,
  setCombinationTotalStake,
  setCombinationPerComboStake,
  syncCombinationsTotalStake,
  clearRejectedCombo,
  acceptCombination,
  rejectCombination,
  setIsCombinationUsingPromo,
} = betslipSlice.actions;

export default betslipSlice.reducer;

export type BetMessage = {
  code?: string;
  createdAt: number;
  data: BetMessageData;
  displayText: string;
  status: string;
  errors: BetMessageError[];
};

export type BetMessageError = {
  messageTemplate: string;
  message: string;
  propertyPath?: string;
};
export type BetMessageData = {
  betIds?: string;
  betId?: string;
  betTab: string;
  entryId: number;
  outcomeIds: string[];
  alternativeStake?: number;
  correlationId: string;
};
